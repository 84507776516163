import { useEffect, useState } from "react"
import { DatePickerWrapper } from "./styles"
import { Controller, RegisterOptions, useFormContext } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { useTranslation } from "react-i18next"
import { IoCalendarOutline } from "react-icons/io5"
import FiCalendar from "@assets/svg/FiCalendar.svg?react"

import {
	DatePicker,
	DatePickerProps,
	LocalizationProvider,
} from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"

interface IProps extends DatePickerProps<any> {
	name: string
	label?: string
	labelVisibility?: boolean
	registerOptions?: RegisterOptions
	withOutErrorMessage?: boolean
}

function CustomDatePicker({
	name,
	label,
	labelVisibility = true,
	registerOptions,
	withOutErrorMessage = false,
	value,
	...rest
}: IProps) {
	/* ------------------------------- Local State ------------------------------ */
	const [isRequired, setIsRequired] = useState(false)
	/* ---------------------------------- Hooks --------------------------------- */
	const { control, formState } = useFormContext()
	const { errors } = formState
	const {
		i18n: { language },
	} = useTranslation()
	/* ------------------------------ Derived State ----------------------------- */
	useEffect(() => {
		if (typeof registerOptions?.required === "object") {
			setIsRequired(registerOptions?.required?.value)
		}
		if (typeof registerOptions?.required === "boolean") {
			setIsRequired(registerOptions.required)
		}
	}, [registerOptions])
	return (
		<DatePickerWrapper
			$islabelVisible={labelVisibility}
			$isError={errors[name] !== undefined ?? false}
			$language={language as "ar" | "en"}
		>
			<label>
				{label && (
					<p>
						{label} {isRequired && <span>*</span>}
					</p>
				)}
				<Controller
					name={name}
					control={control}
					rules={registerOptions}
					render={({ field: { onChange, value: local } }) => (
						<>
							{/* <LocalizationProvider dateAdapter={AdapterDayjs}>  */}
							<DatePicker
								{...rest}
								value={dayjs(local)}
								onChange={onChange}
								slots={{
									openPickerIcon: FiCalendar,
								}}
							/>
							{/* </LocalizationProvider> */}
						</>
					)}
				/>
			</label>
			<div className='ErrorMessageStyle'>
				{!withOutErrorMessage && (
					<ErrorMessage
						errors={errors}
						name={name}
						render={({ message }) => <p>{message}</p>}
					/>
				)}
			</div>
		</DatePickerWrapper>
	)
}

export default CustomDatePicker
