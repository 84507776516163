import CustomBreadcrumbs from "@Common/CustomBreadcrumbs"
import WhiteCard from "@Common/WhiteCard"
import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import CustomButton from "@Common/CustomButton"
import { ManualReservationWrapper } from "./styles"
import MoneysIcon from "@assets/svg/MoneysIcon.svg?react"
import UserOctagonIcon from "@assets/svg/UserOctagonIcon.svg?react"
import ClipboardTextIcon from "@assets/svg/ClipboardTextIcon.svg?react"
import CalendarAddIcon from "@assets/svg/CalendarAddIcon.svg?react"
import CustomTimeLine from "@Common/CustomTimeLine"
import PropertyInfoFormInputs, {
	IFormPropertyInfoFormInputs,
} from "./PropertyInfoFormInputs"
import { useContext, useEffect, useState } from "react"
import PricingFormInputs, { IPricingFormInputs } from "./PricingFormInputs"
import ReviewFormInputs, { IReviewFormInputs } from "./ReviewFormInputs"
import GustInfoFormInputs, { IGustInfoFormInputs } from "./GustInfoFormInputs"
import { usePostManualReservation } from "@services"
import { ToastMessageContext } from "@context/toast-message-cotnext"
import dayjs from "dayjs"
import PopupConfirmation from "./popupConfirmation"
import CustomLoading from "../CustomLoading"

export default function ManualReservation() {
	const [isloaderState, setIsloaderState] = useState(false)
	const [confirmationMessage, setConfirmationMessage] = useState(false)
	const { showSuccess } = useContext(ToastMessageContext)
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [isActiveStep, setIsActiveStep] = useState(1)
	const [getPropertyData, setPropertyData] =
		useState<IFormPropertyInfoFormInputs>()
	const [getPricingData, setPricingData] = useState<IPricingFormInputs>()
	const [getGustData, setGustData] = useState<IGustInfoFormInputs>()
	const [getReviewData, setReviewData] = useState<IReviewFormInputs>()
	const [reviewData, setGetReviewData] = useState<IReviewFormInputs>()
	const { mutateAsync: mutatePostManualReservation } =
		usePostManualReservation()
	const TimeLineListData = [
		{
			label: t("MANUALRESERVATION.RESERVATIONINFORMATION"),
			describtion: t("MANUALRESERVATION.PROPERTYINFORMATIONANDRESERVATIONDATE"),
			icon: <CalendarAddIcon />,
			isActive: isActiveStep === 1,
			isDone: isActiveStep > 1,
		},
		{
			label: t("MANUALRESERVATION.PRICINGDATA"),
			describtion: t("MANUALRESERVATION.NIGHTLYRATEANDTOTALPRICE"),
			icon: <MoneysIcon />,
			isActive: isActiveStep === 2,
			isDone: isActiveStep > 2,
		},
		{
			label: t("MANUALRESERVATION.GUESTDATA"),
			describtion: t("MANUALRESERVATION.GUESTNAMEANDMOBILENUMBER"),
			icon: <UserOctagonIcon />,
			isActive: isActiveStep === 3,
			isDone: isActiveStep > 3,
		},
		{
			label: t("MANUALRESERVATION.REVIEW"),
			describtion: t("MANUALRESERVATION.REVIEWTHEDATAANDCONFIRMTHERESERVATION"),
			icon: <ClipboardTextIcon />,
			isActive: isActiveStep === 4,
			isDone: false,
		},
	]

	const goBack = () => {
		if (isActiveStep === 1) return
		setIsActiveStep(isActiveStep - 1)
	}
	const onSubmitForm = async () => {
		setIsloaderState(true)
		const responseData = {
			id: 0,
			propertyId: Number(getPropertyData?.propertyId),
			propertyName: getPropertyData?.propertyName,
			checkIn: dayjs(getPropertyData?.checkIn).format("YYYY-MM-DD"),
			checkOut: dayjs(getPropertyData?.checkOut).format("YYYY-MM-DD"),
			checkInTime: getPropertyData?.checkInTime,
			checkOutTime: getPropertyData?.checkOutTime,
			totalPrice: Number(getPricingData?.totalPrice),
			firstName: getGustData?.firstName,
			lastName: getGustData?.lastName,
			guestPhoneNumber: getGustData?.guestPhoneNumber,
			isAutoAccess: getGustData?.isAutoAccess,
			HostNote: reviewData,
		}
		const response = await mutatePostManualReservation({ ...responseData })

		if (response.status === 201) {
			setIsloaderState(false)
			navigate("/calendar")
			showSuccess(t("MANUALRESERVATION.RESERVATIONHASBEENSUCCESSFULLYSAVED"))
		}
		setIsloaderState(false)
	}
	useEffect(() => {
		setGetReviewData(getReviewData)
	}, [getReviewData, setGetReviewData])
	return (
		<ManualReservationWrapper>
			{isloaderState && <CustomLoading />}
			{confirmationMessage && (
				<PopupConfirmation
					setConfirmationMessage={setConfirmationMessage}
					isActiveStep={isActiveStep}
					onSubmitForm={onSubmitForm}
				/>
			)}
			<Grid container spacing={"24px"}>
				<Grid item xs={12}>
					<CustomBreadcrumbs
						Links={[
							{
								label: t("CALENDAR.CALENDAR"),
								onClick: () => navigate("/calendar"),
							},
						]}
						currantLink={t("MANUALRESERVATION.CREATEMANUALRESERVATION")}
					/>
				</Grid>
				<Grid item xs={12}>
					<WhiteCard>
						<div className='containerTitleSection'>
							<p>{t("MANUALRESERVATION.CREATEMANUALRESERVATION")}</p>
						</div>
						<div className='containerForms'>
							<CustomTimeLine listData={TimeLineListData} />
							<div>
								{isActiveStep === 1 && (
									<PropertyInfoFormInputs
										setIsActiveStep={setIsActiveStep}
										isActiveStep={isActiveStep}
										setPropertyData={setPropertyData}
										getPropertyData={getPropertyData}
									/>
								)}
								{isActiveStep === 2 && (
									<PricingFormInputs
										setIsActiveStep={setIsActiveStep}
										isActiveStep={isActiveStep}
										getPricingData={getPricingData}
										setPricingData={setPricingData}
										getPropertyData={getPropertyData}
									/>
								)}
								{isActiveStep === 3 && (
									<GustInfoFormInputs
										setIsActiveStep={setIsActiveStep}
										isActiveStep={isActiveStep}
										getGustData={getGustData}
										setGustData={setGustData}
									/>
								)}
								{isActiveStep === 4 && (
									<ReviewFormInputs
										getPropertyData={getPropertyData}
										getPricingData={getPricingData}
										getGustData={getGustData}
										setReviewData={setReviewData}
									/>
								)}
							</div>
						</div>
						<div className='containerFooterButtons'>
							<div>
								{isActiveStep > 1 && (
									<CustomButton
										label={t("CANCEL")}
										onClick={() => setConfirmationMessage(true)}
									/>
								)}
							</div>
							<div>
								<CustomButton
									label={
										isActiveStep === 1
											? t("CANCEL")
											: t("MANUALRESERVATION.PREVIOUS")
									}
									onClick={() =>
										isActiveStep === 1 ? setConfirmationMessage(true) : goBack()
									}
								/>
								<CustomButton
									label={
										isActiveStep < 4
											? t("COMMON.NEXT")
											: t("MANUALRESERVATION.CONFIRMTHERESERVATION")
									}
									withBG
									type='submit'
									form='FormManualReservation'
									onClick={() =>
										isActiveStep === 4 ? onSubmitForm() : undefined
									}
								/>
							</div>
						</div>
					</WhiteCard>
				</Grid>
			</Grid>
		</ManualReservationWrapper>
	)
}
