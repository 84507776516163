import FullCalendar from "@fullcalendar/react"
import { MutableRefObject, useRef } from "react"
import { GrNext } from "react-icons/gr"
import { GoToPrevWrapper } from "./styles"
import { useRecoilState } from "recoil"
import { NewCalenderPageCount } from "@store/index"
import dayjs from "dayjs"

interface IProps {
	calendarRef: MutableRefObject<FullCalendar | null>
	switchView: "dayGridMonth" | "resourceTimelineWeek"
}
export default function GoToPrev({ calendarRef, switchView }: IProps) {
	const [pageCount, setPageCount] = useRecoilState(NewCalenderPageCount)
	const currentDate = dayjs().format("MM")
	const previousEndOfMonth = useRef<string | null>(currentDate)

	const goToPrev = () => {
		const calendarApi = calendarRef.current?.getApi()
		if (calendarApi) {
			calendarApi.prev() // Navigate to the next period
			setPageCount(pageCount - 1)
			// if (switchView === "dayGridMonth") {
			// }
			// if (switchView === "resourceTimelineWeek") {
			// 	const currentDate = dayjs().format("MM")
			// 	const currentView = calendarApi?.view
			// 	const endOfMonth = dayjs(currentView?.activeEnd).format("MM")
			// 	const currentYear = dayjs().format("YYYY")
			// 	const endOfYear = dayjs(currentView?.activeEnd).format("YYYY")
			// 	if (previousEndOfMonth.current !== endOfMonth) {
			// 		if (
			// 			Number(endOfYear) < Number(currentYear) ||
			// 			Number(endOfMonth) < Number(currentDate)
			// 		) {
			// 			setPageCount((prev) => prev - 1)
			// 		}

			// 		previousEndOfMonth.current = endOfMonth
			// 	}
			// }
		}
	}
	return (
		<GoToPrevWrapper>
			<button onClick={goToPrev}>
				<GrNext />
			</button>
		</GoToPrevWrapper>
	)
}
