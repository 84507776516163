import GathernLogo from '@assets/svg/gathren.svg?react';
import AirBnbLogo from '@assets/svg/airbnb.svg?react';
import Booking from  '@assets/svg/Booking.svg?react';
import SmallBooking from '@assets/svg/smallBooking.svg?react';

import { ReactNode } from 'react';

export const platformConfig: Record<number, { color: string; logo: ReactNode; label: string }> = {
  0: {
    color: '#ff5a5f',
    logo: <AirBnbLogo />,
    label: 'AIRBNB',
  },
  1: { 
    color: '#5a409b',
    logo: <GathernLogo />,
    label: 'GATHERIN',
  },
  2: {
    color: '#5a409b',
    logo: <SmallBooking style={{width:'68px',height:'68px',}} />, 
    label: 'Booking.com',
  },
};