import {
	DataDisabelWrapper,
	EventContentEmpty,
	EventContentWrapper,
} from "./styles"
import AirbnbIcon from "@assets/svg/airbnb.svg?react"
import GathernIcon from "@assets/svg/GathernIcon2.svg?react"
import BookingIcon from "@assets/svg/BookingIcon.svg?react"
import ChannelAgoda from "@assets/svg/ChannelAgoda.svg?react"
import MsoolIcon from "@assets/svg/msool-icon.svg?react"
import { useTranslation } from "react-i18next"
import { useRecoilState, useSetRecoilState } from "recoil"
import { PopupOverlayNewCalender, reservationDetails } from "@store/index"
import { Fragment } from "react/jsx-runtime"
import { useEffect, useMemo, useState } from "react"
import { SlLock } from "react-icons/sl"
import { Tooltip } from "@mui/material"
import dayjs from "dayjs"
import { IResponseGetCalenderData } from "src/services/calender/getCalenderData/types"

interface IProps {
	getCalenderData: IResponseGetCalenderData | undefined
	channelName: "Airbnb" | "GatherIn" | "Booking" | "Agoda" | "Msool"
	title: string
	totalPrice: string
	bookingStatus: "مؤكد" | "new" | "cancelled" | "ملغي"
	checkIn: string | Date
	checkOut: string | Date
	guestPhoneNumber: string
	propertyId: string
	propertyName: string
	reservationId: string
	display: "background" | null
	cellPrice: string
	date: string
	availability_type: string
}
interface IChannals {
	Airbnb: JSX.Element
	GatherIn: JSX.Element
	Booking: JSX.Element
	Agoda: JSX.Element
	Msool: JSX.Element
}
const channals: IChannals = {
	Airbnb: <AirbnbIcon />,
	GatherIn: <GathernIcon />,
	Booking: <BookingIcon />,
	Agoda: <ChannelAgoda />,
	Msool: <MsoolIcon />,
}

export default function EventContent({
	channelName,
	title,
	bookingStatus,
	totalPrice,
	checkIn,
	checkOut,
	guestPhoneNumber,
	propertyId,
	propertyName,
	reservationId,
	display,
	cellPrice,
	getCalenderData,
	date,
	availability_type,
}: IProps) {
	/* ------------------------------- Local State ------------------------------ */
	const cellDate = dayjs(date) // Convert the cell's date to Day.js
	const startOftoday = dayjs().startOf("day")
	const isPast = cellDate.isBefore(startOftoday) ? true : false
	const [reservationDetailsValue, setReservationDetails] =
		useRecoilState(reservationDetails)
	const [isPopupOverlayNewCalender, setPopupOverlayNewCalender] =
		useRecoilState(PopupOverlayNewCalender)

	/* ---------------------------------- Hooks --------------------------------- */
	const { t } = useTranslation()
	/* -------------------------------- Functions ------------------------------- */
	const stylebookingStatus = (bookingStatus: string) => {
		if (
			(bookingStatus === "مؤكد" || bookingStatus === "new") &&
			availability_type === "reservation" &&
			!isPast
		) {
			return "green"
		}

		if (
			(bookingStatus === "reservation_request" || bookingStatus === "معلق") &&
			!isPast
		) {
			return "yellow"
		}
		return "gray"
	}

	const funToGetAllReservationDerails = () => {
		setReservationDetails({
			bookingStatus: bookingStatus,
			channelName: channelName,
			checkIn: checkIn,
			checkOut: checkOut,
			guestName: title,
			guestPhoneNumber: guestPhoneNumber,
			propertyId: propertyId,
			propertyName: propertyName,
			reservationId: reservationId,
			totalPrice: totalPrice,
		})
		setPopupOverlayNewCalender({
			isOpen: true,
			openModule: "reservationDetails",
		})
	}
	/* --------------------------------- Hotfix --------------------------------- */
	const disabledDates = useMemo(() => {
		const data = getCalenderData?.result.flatMap((item) => item.dates)
		const getDataFilter = data?.filter(
			(item) =>
				item.availability === "unavailable" && item.availability_type === "busy"
		)
		const daysUnavailable = getDataFilter?.map((item) => {
			return { date: item?.date?.toString(), id: item?.propertyId?.toString() }
		})
		return daysUnavailable ?? []
	}, [getCalenderData])
/* --------------------------------- hotfix --------------------------------- */
	const isBlockDay = (theDate: string, theId: string) => {
		const validateDate = disabledDates.some(
			(item) => item.date === theDate && item.id === theId
		)
		return validateDate
	}
	useEffect(() => {
		if (!isPopupOverlayNewCalender?.isOpen) {
			setReservationDetails(undefined)
		}
	}, [isPopupOverlayNewCalender, setReservationDetails])
	return (
		<Fragment>
			{display !== "background" && (
				<Tooltip title={title} placement='top' arrow followCursor>
					<EventContentWrapper
						bookingStatus={stylebookingStatus(bookingStatus)}
						$isActive={reservationId === reservationDetailsValue?.reservationId}
					>
						<div
							className='custom-event'
							onClick={() => {
								funToGetAllReservationDerails()
							}}
						>
							<span className='containerIcon'>{channals[channelName]}</span>
							<span className='containerContent'>
								{title && title?.substr(0, 10) + "\u2026"}
							</span>
							<span className='containerContent'>
								{totalPrice} {t("GENERAL.SR")}
							</span>
						</div>
					</EventContentWrapper>
				</Tooltip>
			)}
			{display === "background" && isBlockDay(date, propertyId?.toString()) && (
				<DataDisabelWrapper>
					<div>
						<SlLock />
					</div>
				</DataDisabelWrapper>
			)}

			{display === "background" &&
				!isBlockDay(date, propertyId?.toString()) && (
					<EventContentEmpty $isPast={isPast}>
						<span>
							{cellPrice} {t("GENERAL.SR")}
						</span>
					</EventContentEmpty>
				)}
		</Fragment>
	)
}
