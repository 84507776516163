import { FC, useContext, useMemo } from 'react';
import { Box, Button, Grid, MenuItem, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { useAdvanceSettings, useProperty } from '@services';
import { ToastMessageContext } from '@context';
import RadioItem from '../../RadioItem';

interface IForm {
  channel: number;
  propertyName: string;
  instantBookSetting: string;
}
const TabFour: FC<{
  handleClose: () => void;
}> = ({ handleClose }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { instantBookingSettingsQuery, iarBnbInstantBookingMutate } = useAdvanceSettings();
  const { showError, showSuccess } = useContext(ToastMessageContext);

  const { data } = instantBookingSettingsQuery();
  const { mutate } = iarBnbInstantBookingMutate({
    onSuccess: () => {
      handleClose();
      showSuccess(t('CALENDAR.INSTANT_BOOKING_UPDATED'));
    },
    onError: () => showError(t('ERRORS.ERROR_500_MESSAGE')),
  });
  const { control, handleSubmit, watch } = useForm<IForm>({ mode: 'onChange' });
  const { getManagementListQuery } = useProperty();
  const channel = watch('channel');
  const onSubmit = (values: IForm) => {
    mutate({ propertyName: values.propertyName.toString(), instantBookSetting:data.find((x:any)=>x.name==values.instantBookSetting.toString())?.id  });
  };
  const { data: managementList } = getManagementListQuery();

  const propertyList = useMemo(() => {
    if (channel == 2) {
      return managementList?.filter((i:any) => (i?.airbnbUnitId ?? '').length > 0) ?? [];
    } else if (channel == 1) {
      return managementList?.filter((i:any) => (i?.gathrenUnitId ?? '').length > 0) ?? [];
    }
    return [];
  }, [channel, managementList]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container gap={2} className={'py-4'}>
        <Grid item xs={12}>
          <Controller
            name='channel'
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Typography mb={1}>
                  <span className='font-semibold'>{t('CALENDAR.CHOOSE_CHANNEL')}</span>
                </Typography>

                <RadioGroup value={value} onChange={onChange}>
                  <div className={'flex gap-2'}>
                    <RadioItem small text={t('AIRBNB')} value={2} itemValue={value} />
                  </div>
                </RadioGroup>
              </>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name='propertyName'
            defaultValue={''}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Typography mb={1}>
                  <span className='font-semibold'>{t('CALENDAR.CHOOSEـPROPERTY')}</span>
                </Typography>
                <Select
                  size={'small'}
                  onChange={onChange}
                  fullWidth
                  error={Boolean(error)}
                  id='property'
                  placeholder={t('CALENDAR.CHOOSE')}
                  className='!rounded-3xl'
                  value={value}
                >
                  {propertyList?.map((property: any) => (
                    <MenuItem key={property?.propertyName} value={property.propertyName}>
                      {property?.propertyName}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='instantBookSetting'
            defaultValue={''}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Typography mb={1}>
                  <span className='font-semibold'>{t('CALENDAR.INSTANT_BOOK_OPTIONS')}</span>
                </Typography>
                <Select
                  onChange={onChange}
                  fullWidth
                  error={Boolean(error)}
                  size={'small'}
                  id='property'
                  placeholder={t('CALENDAR.CHOOSE')}
                  className='!rounded-3xl'
                  value={value}
                >
                  {data?.map((item: any) => (
                    <MenuItem key={item?.name} value={item.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Button type={'submit'} variant={'contained'} fullWidth>
              {t('SAVE')}
            </Button>
            <Button variant='outlined' fullWidth color={'info'} onClick={handleClose}>
              {t('GENERAL.CANCEL_ALT')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
export default TabFour;
