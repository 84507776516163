import { MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { SelectClassficationWrapper } from "./styles"
import { useGuestManagement } from "@services"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useSetRecoilState } from "recoil"
import { classfication } from "@store/index"
import { useTranslation } from "react-i18next"

interface IProps {
	data: string
	guestName: string
	setClassficationPopup: Dispatch<SetStateAction<boolean>>
}

export default function SelectClassfication({
	data,
	guestName,
	setClassficationPopup,
}: IProps) {
	const setClassficationState = useSetRecoilState(classfication)
	const [selectedClassficationValue, setSelectedClassficationValue] =
		useState("")
	const { GuestManagementClassificationQuery } = useGuestManagement()

	const { data: classificationQuery } = GuestManagementClassificationQuery()

	const onChangeFun = async (data: SelectChangeEvent<string>) => {
		setClassficationPopup(true)
		setClassficationState({
			classficationId: data.target.value,
			guestName: guestName,
			onDone:()=>{
				setSelectedClassficationValue(data.target.value.toString());
			}
		})
	}

	useEffect(() => {
		const CurrantData = classificationQuery?.data.result.filter(
			(item) => item.name === data
		)
		console.log('CurrantData ', CurrantData);
		if(CurrantData?.length===0)
			{
				setSelectedClassficationValue('UnCategorized')
			} else{
				setSelectedClassficationValue(CurrantData?.[0]?.lookupId.toString()!)
			}
	}, [setSelectedClassficationValue, classificationQuery]);

	const {t}=useTranslation();

	return (
		<SelectClassficationWrapper>
			<Select
				value={selectedClassficationValue}
				onChange={(data) => onChangeFun(data)}
				sx={{
					height: "25px",
					width: "55%",
					borderRadius: "35px",
					color: "#000",
					'.MuiOutlinedInput-notchedOutline': {
						borderColor: 'rgba(228, 219, 233, 0.25)',
					  },
					  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: 'rgba(228, 219, 233, 0.25)',
					  },
					  '&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: 'rgba(228, 219, 233, 0.25)',
					  },
					background:
						selectedClassficationValue === "158"
							? "#e4fce6"
							: selectedClassficationValue === "157"
								? "#e8e8e8"
								: selectedClassficationValue === "159"
									? "#ffe0e3"
									: "#ccc",
				}}
				label=''
			>
				<MenuItem
						key={'UnCategorized'}
						value={'UnCategorized'}
						style={{ color: "#9CA3AF" }}
						disabled
					>
						{/* {t('CONTROL.UnCategorized')} */
						'غير مصنف'
						}
					</MenuItem>
				{classificationQuery?.data.result.map((item: any) => (
					<MenuItem
						key={item?.lookupId}
						value={item?.lookupId}
						style={{ color: "#9CA3AF" }}
					>
						{item?.name}
					</MenuItem>
				))}
			</Select>
		</SelectClassficationWrapper>
	)
}