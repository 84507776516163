import { createTemplateURL, deleteTemplateURL, getUserTemplatesURL, updateTemplateURL } from '../utils/constants';
import { useApi } from './useApi';
import { useMutation, UseMutationResult, useQuery } from '@tanstack/react-query';
import { IIncome, IProperty, ITemplate } from '@interfaces';

const useTemplate = () => {
  const { apiPrivate } = useApi();

  const createTemplate = async (body: ITemplate) => {
    const { data } = await apiPrivate.post(createTemplateURL, body);
    return data;
  };
  const updateTemplate = async (body: ITemplate) => {
    const { id, ...rest } = body;
    const { data } = await apiPrivate.put(`${updateTemplateURL}/${id}`, rest);
    return data;
  };

  const deleteTemplate = async (id: number) => {
    const { data } = await apiPrivate.delete(`${deleteTemplateURL}/${id}`);
    return data;
  };

  const getTemplates = async () => {
    const { data } = await apiPrivate.get<any>(getUserTemplatesURL);
    return data?.result
    ;
  };

  const createTemplateMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: () => void;
  }): UseMutationResult<void, Error, ITemplate, unknown> =>
    useMutation({
      mutationFn: (body: ITemplate) => createTemplate(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });
  const updateTemplateMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: () => void;
  }): UseMutationResult<void, Error, ITemplate, unknown> =>
    useMutation({
      mutationFn: (body: ITemplate) => updateTemplate(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });
  const deleteTemplateMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: () => void;
  }): UseMutationResult<void, Error, number, unknown> =>
    useMutation({
      mutationFn: (id: number) => deleteTemplate(id),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const getTemplatesQuery = () =>
    useQuery({
      queryKey: [getUserTemplatesURL],
      queryFn: ({ queryKey }) => getTemplates(),
    });

  return { createTemplateMutate, getTemplatesQuery, deleteTemplateMutate, updateTemplateMutate };
};
export { useTemplate };
