import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, MenuItem, Select, Typography, TextField, Grid, RadioGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingWrapper } from '@components';

import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { useAdvanceSettings, useProperty } from '@services';
import dayjs from 'dayjs';
import { ToastMessageContext } from '@context';
import RadioItem from '../../RadioItem';

interface IForm {
  property?: string;
  channel?: number;
  from?: string;
  to?: string;
  newPrice: string;
  weekenPrice?: string;
  daysPrice?: string
}
const TabOne: FC<{
  handleClose: () => void;
}> = ({ handleClose }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { updateAllChannelsPrice } = useAdvanceSettings();
  const { showError, showSuccess } = useContext(ToastMessageContext);

  const { mutate, isPending } = updateAllChannelsPrice({

    onSuccess: () => {
      handleClose();
      showSuccess(t('CALENDAR.PRICE_UPDATE_SUCCESS'));
    },
    onError: () => showError(t('ERRORS.ERROR_500_MESSAGE')),
  });
  const { control, handleSubmit, watch, setValue } = useForm<IForm>({ mode: 'onChange' });
  const { getManagementListQuery } = useProperty();

  const channel = watch('channel');
  const propertyId = watch('property');

  const { data: managementList } = getManagementListQuery();
  const isGathernSelect = channel == 1
  const isAllChannelsSelected = channel == 3
  const isAribnbnSelected = channel == 2
  const propertyList = useMemo(() => {
    if (channel == 2) {
      return managementList?.filter((i:any) => (i?.airbnbUnitId ?? '').length > 0) ?? [];
    } else if (channel == 1) {
      return managementList?.filter((i:any) => (i?.gathrenUnitId ?? '').length > 0) ?? [];
    } else {
      return managementList?.filter((i:any)=>i.gathrenUnitId?.length && i.airbnbUnitId?.length )
    }
    return [];
  }, [channel, managementList]);

  const selectedProperty = useMemo(() => {

    return managementList?.find((i: any) => i?.propertyId === propertyId);

  }, [propertyList, propertyId]);
  const onSubmit = (values: any) => {
    let body = {
      listing_id: selectedProperty!.propertyId.toString(),
      date_from: values?.from?.format('YYYY-MM-DD'),
      date_to: values?.to?.format('YYYY-MM-DD'),
      rate: isGathernSelect ? "" : isAribnbnSelected ? `${values.newPrice ?? 0}.00` : `${values.daysPrice ?? 0}.00`,
      min_stay: 0,
    }
    let newBody = {
      listing_id: selectedProperty!.propertyId.toString(),
      date_from: values?.from?.format('YYYY-MM-DD'),
      date_to: values?.to?.format('YYYY-MM-DD'),
      rate: isGathernSelect ? "" : isAribnbnSelected ? `${values.newPrice ?? 0}.00` : `${values.daysPrice ?? 0}.00`,
      "units":isAribnbnSelected? null: {
        "mid": isAribnbnSelected ? 0 : values?.daysPrice,
        "thu": isAribnbnSelected ? 0 : values?.weekenPrice,
        "fri": isAribnbnSelected ? 0 : values?.weekenPrice,
        "sat": isAribnbnSelected ? 0 : values?.weekenPrice,
      }
    }
    mutate(newBody);
  };
useEffect(()=>{
  setValue("channel", 2 )
},[])
  return (
    <LoadingWrapper loading={isPending}>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container gap={2} className={'py-4'}>
        <Grid item xs={12}>
          <Controller
            name='channel'
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Typography mb={1}>
                  <span className='font-semibold'>{t('CALENDAR.CHOOSE_CHANNEL')}</span>
                </Typography>

                <RadioGroup defaultValue={2} value={value} onChange={(e)=>{
                    setValue("channel", Number(e.target.value))
                    setValue("property", '')
                }}>
                   <div className={'flex gap-2'}>
                    <RadioItem  small text={t('GATHERN')} value={1} itemValue={value} />
                  </div>
                  <Box sx={{mt:1}} className={'flex gap-2'}> 
                    <RadioItem small text={t('AIRBNB')} value={2} itemValue={value} />
                  </Box>
                 
                </RadioGroup>
              </>
            )}
          />
        </Grid>
      
          {/* <Grid item xs={12}>
            <Typography mb={1}>
              <span className='font-semibold'>{t('CALENDAR.CHOOSE_CHANNEL')}</span>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start',mr:-1 }} >
            <FormControlLabel
                style={{ margin: 0 }}

                control={
                  <Checkbox
                    checked={isAllChannelsSelected}
                    onChange={(e) => {
                      setValue("channel", 3)
                      setValue("property", '')

                    }}
                  />
                }
                label={(t('COMMON.ALL'))}
              />
              <FormControlLabel
                style={{ margin: 0 }}
                control={
                  <Checkbox
                    checked={isAribnbnSelected}
                    onChange={(e) => {
                      setValue("channel", 2)
                      setValue("property", '')

                    }}
                  />
                }
                label={t('AIRBNB')}
              />
             
              <FormControlLabel
                style={{ margin: 0 }}

                control={
                  <Checkbox
                    checked={isGathernSelect}
                    onChange={(e) => {
                      setValue("channel", 1)
                      setValue("property", '')


                    }}
                  />
                }
                label={t('GATHERN')}
              />
            </Box>

          </Grid> */}
          <Grid item xs={12}>
            
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='property'
              defaultValue={''}
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Typography mb={1}>
                    <span className='font-semibold'>{t('CALENDAR.CHOOSEـPROPERTY')}</span>
                  </Typography>

                  <Select
                    onChange={onChange}
                    fullWidth
                    error={Boolean(error)}
                    id='property'
                    placeholder={t('CALENDAR.CHOOSE')}
                    className='!rounded-3xl'
                    value={value}
                  >
                    {propertyList?.map((property: any) => (
                      <MenuItem key={property?.propertyId} value={property.propertyId}>
                        {property?.propertyName}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </Grid>

          {isAribnbnSelected || isAllChannelsSelected ? <>
            <Typography variant='h6'>{t('CALENDAR.PERIOD')}</Typography>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <Controller
                    name='from'
                    control={control}
                    rules={{
                      required: isAribnbnSelected || isAllChannelsSelected,
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <Typography mb={1}>
                          <span className='font-semibold'>{t('CALENDAR.FROM')}</span>
                        </Typography>
                        <DatePicker
                          onChange={onChange}
                          value={value}
                          slotProps={{
                            textField: {
                              error: Boolean(error),
                              InputProps: { sx: { borderRadius: '1.5rem' } },
                              dir: language == 'ar' ? 'rtl' : 'ltr',
                            },
                          }}
                          format={'YYYY-MM-DD'}
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name='to'
                    control={control}
                    rules={{
                      required: isAribnbnSelected || isAllChannelsSelected,
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <Typography mb={1}>
                          <span className='font-semibold'>{t('CALENDAR.TO')}</span>
                        </Typography>

                        <DatePicker
                          onChange={onChange}
                          value={value}
                          className='!rounded-3xl'
                          slotProps={{
                            textField: {
                              error: Boolean(error),
                              InputProps: { sx: { borderRadius: '1.5rem' } },
                              dir: language == 'ar' ? 'rtl' : 'ltr',
                            },
                          }}
                          format={'YYYY-MM-DD'}
                        />
                      </>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </> : null}



          {isAribnbnSelected ? <Grid item xs={12}>
            <Controller
              name='newPrice'
              control={control}
              rules={{
                required: isAribnbnSelected,
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Typography mb={1}>
                    <span className='font-semibold'>{t('CALENDAR.NEW_PRICE')}</span>
                  </Typography>

                  <TextField
                    onChange={(e) => {
                      const val: number = Number(e?.target?.value);
                      if (!isNaN(val)) {
                        onChange(e);
                      }
                    }}
                    type={'number'}
                    error={Boolean(error)}
                    helperText={t(error?.message ?? '')}
                    InputProps={{ sx: { borderRadius: '1.5rem' } }}
                    value={value}
                    fullWidth
                  />
                </>
              )}
            />
          </Grid> : null}
          {isGathernSelect || isAllChannelsSelected ? <>
            <Grid item xs={12}>
              <Controller
                name='weekenPrice'
                control={control}
                rules={{
                  required: isGathernSelect || isAllChannelsSelected,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <Typography mb={1}>
                      <span className='font-semibold'>{t('COMMON.WEEK_END_PRICE')}</span>
                    </Typography>

                    <TextField
                      onChange={(e) => {
                        const val: number = Number(e?.target?.value);
                        if (!isNaN(val)) {
                          onChange(e);
                        }
                      }}
                      type={'number'}
                      error={Boolean(error)}
                      helperText={t(error?.message ?? '')}
                      InputProps={{ sx: { borderRadius: '1.5rem' } }}
                      value={value}
                      fullWidth
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='daysPrice'
                control={control}
                rules={{
                  required: isGathernSelect || isAllChannelsSelected,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <Typography mb={1}>
                      <span className='font-semibold'>{t('تسعيير باقي ايام الاسبوع')}</span>
                    </Typography>

                    <TextField
                      onChange={(e) => {
                        const val: number = Number(e?.target?.value);
                        if (!isNaN(val)) {
                          onChange(e);
                        }
                      }}
                      type={'number'}
                      error={Boolean(error)}
                      helperText={t(error?.message ?? '')}
                      InputProps={{ sx: { borderRadius: '1.5rem' } }}
                      value={value}
                      fullWidth
                    />
                  </>
                )}
              />
            </Grid>
          </> : null}


          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <Button type={'submit'} variant={'contained'} fullWidth>
                {t('SAVE')}
              </Button>
              <Button variant='outlined' fullWidth color={'info'} onClick={handleClose}>
                {t('GENERAL.CANCEL_ALT')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </LoadingWrapper>
  );
};
export { TabOne };
