import { atom } from "recoil"
import { ATOMS_KEYS } from "../atom-keys"
interface IReservationDetails {
	bookingStatus: string
	channelName: "Airbnb" | "GatherIn" | "Booking" | "Agoda" | "Msool"
	checkIn: string | Date
	checkOut: string | Date
	guestName: string
	guestPhoneNumber: string
	propertyId:string
	propertyName:string
	reservationId:string
	totalPrice:string
}
export const reservationDetails = atom<IReservationDetails | undefined>({
	key: ATOMS_KEYS.RESERVATIONDETAILS,
	default: undefined,
})
