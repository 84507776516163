const Logo = '/images/Logo.png';
const MsoolIcon = '/images/msool-icon.jpg';
const Background = '/images/Background.png';
const PublicBg = '/images/heropage.jpg';
const registerNow = '/images/register_now.png';
const BackgroundLocks = '/images/BackgroundLocks.png';
const MessagingBg = '/images/MessagingBg.png';
const featuresBg = '/images/features/features_bg.png';
const BackgroundP = '/images/BackgroundP.png';
const BackgroundCompressed = '/images/BackgroundCompressed.png';
const InActiveBg = '/images/InActiveModal.png'; 
const SubscribeNow = '/images/subscribebanner.png'; 
const LeepBackground = '/images/leepBackk.png'


export {
  InActiveBg,
  Logo,
  MsoolIcon,
  BackgroundLocks,
  PublicBg,
  featuresBg,
  registerNow,
  Background,
  BackgroundP,
  MessagingBg,
  BackgroundCompressed,
  SubscribeNow,
  LeepBackground
};
