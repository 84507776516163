import CustomDatePicker from "@Common/CustomDatePicker"
import CustomSelect from "@Common/CustomSelect"
import { loaderState, PopupOverlayNewCalender } from "@store/index"
import dayjs, { Dayjs } from "dayjs"
import { useContext, useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useRecoilState, useSetRecoilState } from "recoil"
import { PricingTabWrapper } from "./styles"
import { IResponseGetListingManagement } from "src/services/calender/getListingManagement/types"
import { APIS_KEYS, usePostAllPlatformPrice } from "@services"
import { useQueryClient } from "@tanstack/react-query"
import Input from "@Common/input"
import { FORM_REGEX_VALIDATORS } from "@utils"
import ChannelsListActive from "../channelsListActive"
import { ToastMessageContext } from "@context/toast-message-cotnext"

interface IForm {
	date_from: dayjs.Dayjs | string
	date_to: dayjs.Dayjs | string
	propertiesId: string
	rate: string
}
interface IProps {
	getListingManagement: IResponseGetListingManagement | undefined
}
export default function PricingTab({ getListingManagement }: IProps) {
	/* ------------------------------- Local State ------------------------------ */
	const [isPopupOverlayNewCalender, setPopupOverlayNewCalender] =
		useRecoilState(PopupOverlayNewCalender)
	const setLoaderState = useSetRecoilState(loaderState)
	/* ---------------------------------- Hooks --------------------------------- */
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const methods = useForm<IForm>()
	const { setValue, watch, reset } = methods
	const date_from_watch = watch("date_from")
	const date_to_watch = watch("date_to")
	const propertiesId_watch = watch("propertiesId")
	const queryClient = useQueryClient()
	const { showSuccess, showError } = useContext(ToastMessageContext)

	/* ---------------------------------- API'S --------------------------------- */
	const { mutateAsync: mutateAsyncAllPlatformPrice } = usePostAllPlatformPrice()
	/* -------------------------------- Function -------------------------------- */
	const validMidDays: number[] = [0, 1, 2, 3] // Sunday to Wednesday
	const validThuDays: number[] = [4] // Thursday
	const validFriDays: number[] = [5] // Friday
	const validSatDays: number[] = [6] // Saturday

	function includesMidDays(start: Dayjs, end: Dayjs): "mid" | false {
		let currentDate: Dayjs = start

		while (currentDate.isBefore(end) || currentDate.isSame(end)) {
			if (validMidDays.includes(currentDate.day())) return "mid"
			currentDate = currentDate.add(1, "day")
		}

		return false // Return false if no valid day is found
	}
	function includesThuDays(start: Dayjs, end: Dayjs): "thu" | false {
		let currentDate: Dayjs = start

		while (currentDate.isBefore(end) || currentDate.isSame(end)) {
			if (validThuDays.includes(currentDate.day())) return "thu"
			currentDate = currentDate.add(1, "day")
		}

		return false // Return false if no valid day is found
	}
	function includesFriDays(start: Dayjs, end: Dayjs): "fri" | false {
		let currentDate: Dayjs = start

		while (currentDate.isBefore(end) || currentDate.isSame(end)) {
			if (validFriDays.includes(currentDate.day())) return "fri"
			currentDate = currentDate.add(1, "day")
		}

		return false // Return false if no valid day is found
	}

	function includesSatDays(start: Dayjs, end: Dayjs): "sat" | false {
		let currentDate: Dayjs = start

		while (currentDate.isBefore(end) || currentDate.isSame(end)) {
			if (validSatDays.includes(currentDate.day())) return "sat"
			currentDate = currentDate.add(1, "day")
		}

		return false // Return false if no valid day is found
	}

	const dataList = useMemo(() => {
		const data = getListingManagement?.result.map((item) => {
			return {
				value: item.propertyId.toString(),
				label: item.propertyName,
			}
		})
		const newData = [
			{
				value: "0",
				label: t("COMMON.All"),
			},
		]?.concat(data ?? [])
		return newData ?? []
	}, [getListingManagement])

	const listOfChannels = useMemo(() => {
		const list =
			propertiesId_watch !== "0"
				? getListingManagement?.result.filter(
						(item) => item.propertyId.toString() === propertiesId_watch
					)
				: getListingManagement?.result

		const channels = list?.flatMap((item) => item.connectedChannels)

		const listChannels = channels?.map((item) => {
			return {
				channelId: item.channelId,
				channelName: item.channelName,
			}
		})
		const uniqueChannels = listChannels?.filter(
			(channel, index, self) =>
				index === self.findIndex((c) => c.channelId === channel.channelId)
		)
		return uniqueChannels ?? []
	}, [propertiesId_watch, getListingManagement])

	const onSubmit = async (data: IForm) => {
		setLoaderState(true)
		const dataListState =
			data.propertiesId === "0"
				? dataList.map((item) => item.value)
				: dataList
						?.filter((item) => item.value === data.propertiesId)
						?.map((sub) => sub.value)

		const date_from = dayjs(data.date_from).format("YYYY-MM-DD")
		const date_to = dayjs(data.date_to).format("YYYY-MM-DD")
		const airbnb =
			listOfChannels.filter((item) => item.channelId === 1).length !== 0
				? {
						rate: data.rate,
						date_from: date_from,
						date_to: date_to,
						min_stay: "0",
					}
				: null
		const gathren =
			listOfChannels.filter((item) => item.channelId === 2).length !== 0
				? {
						mid: includesMidDays(data.date_from as Dayjs, data.date_to as Dayjs)
							? data.rate
							: "0",
						thu: includesThuDays(data.date_from as Dayjs, data.date_to as Dayjs)
							? data.rate
							: "0",
						fri: includesFriDays(data.date_from as Dayjs, data.date_to as Dayjs)
							? data.rate
							: "0",
						sat: includesSatDays(data.date_from as Dayjs, data.date_to as Dayjs)
							? data.rate
							: isPopupOverlayNewCalender?.saturdayPrice ?? "0",
					}
				: null
		const booking =
			listOfChannels.filter((item) => item.channelId === 3).length !== 0
				? {
						date_from: date_from,
						date_to: date_to,
						rate: data.rate,
					}
				: null
		const responseData = {
			propertiesId: dataListState.filter((item) => item !== "0"),
			categoriesId: [],
			date_from: date_from,
			date_to: date_to,
			airbnb: airbnb,
			gathren: gathren,
			booking: booking,
		}
		const response = await mutateAsyncAllPlatformPrice({ ...responseData })
		if (response?.status === 200) {
			reset()
			setLoaderState(false)
			setPopupOverlayNewCalender(undefined)
			queryClient.invalidateQueries({ queryKey: [APIS_KEYS.GETCALENDERDATA] })
			showSuccess(t("CALENDAR.PRICE_UPDATE_SUCCESS"))
		}
		if (response?.status === 500) {
			response?.data?.errorMessages.map((item: any) => {
				return showError(language === "ar" ? item.messageAr : item.messageEn)
			})
		}
		setLoaderState(false)
	}

	/* ----------------------------- Action on Init ----------------------------- */
	useEffect(() => {
		if (isPopupOverlayNewCalender?.from && isPopupOverlayNewCalender?.to) {
			setValue("date_from", isPopupOverlayNewCalender?.from)
			setValue("date_to", isPopupOverlayNewCalender?.to.subtract(1, "day"))
		}
	}, [isPopupOverlayNewCalender])

	useEffect(() => {
		if (isPopupOverlayNewCalender?.propertyValue?.value) {
			setValue(
				"propertiesId",
				isPopupOverlayNewCalender?.propertyValue?.value.toString()
			)
		}
	}, [isPopupOverlayNewCalender, setValue])

	return (
		<PricingTabWrapper language={language as "ar" | "en"}>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)} id='pricingForm'>
					<div className='containerDate'>
						<label>{t("COMMON.DATE")}</label>
						<div className='dateInputs'>
							<CustomDatePicker
								disablePast
								name='date_from'
								label={t("GENERAL.FROM")}
								registerOptions={{
									required: {
										value: true,
										message: t("COMMON.REQUIRED"),
									},
								}}
							/>
							<CustomDatePicker
								disablePast
								name='date_to'
								label={t("GENERAL.TO")}
								registerOptions={{
									required: {
										value: true,
										message: t("COMMON.REQUIRED"),
									},
									min: {
										value: date_from_watch as string,
										message: t(
											"COMMON.PLEAS_CHOOSE_A_CHECK_OUT_DATE_AFTER_YOUR_CHECK_IN_DATE"
										),
									},
								}}
							/>
						</div>

						<div className='numberOfNights'>
							<p>{t("FINANCE.INCOME.NIGHTS")}</p>
							<span>
								{dayjs(date_to_watch).format("YYYY-MM-DD") !== "Invalid Date" &&
								dayjs(date_from_watch).format("YYYY-MM-DD") !== "Invalid Date"
									? Math.round(
											dayjs(date_to_watch)?.diff(date_from_watch, "d", true)
										) +
											1 <
										1
										? "-"
										: Math.round(
												dayjs(date_to_watch)?.diff(date_from_watch, "d", true)
											) + 1
									: 0}
							</span>
						</div>
					</div>

					<div className='byProperty'>
						<CustomSelect
							data={dataList ?? []}
							label={t("COMMON.PROPERTY")}
							name='propertiesId'
							registerOptions={{
								required: {
									value: true,
									message: t("COMMON.REQUIRED"),
								},
							}}
						/>
					</div>
					<Input
						suffixLabel={t("COMMON.RS")}
						name='rate'
						label={t("COMMON.PRICING")}
						placeholder={
							isPopupOverlayNewCalender?.price &&
							isPopupOverlayNewCalender?.price.length > 1
								? `${isPopupOverlayNewCalender?.price[0]} - ${isPopupOverlayNewCalender?.price[isPopupOverlayNewCalender?.price.length - 1]}`
								: isPopupOverlayNewCalender?.price &&
									  isPopupOverlayNewCalender?.price.length === 1
									? `${isPopupOverlayNewCalender?.price[0]} `
									: ""
						}
						registerOptions={{
							required: {
								value: true,
								message: t("COMMON.REQUIRED"),
							},
							min: {
								value: 38,
								message: t("ERRORS.MINIMUM_VALUE", { number: 38 }),
							},
							pattern: {
								value: FORM_REGEX_VALIDATORS.numbersOnly,
								message: t("COMMON.THE_FIELD_ACCEPTS_NUMBERS_ONLY"),
							},
						}}
					/>

					{/* <ChannelsListActive /> */}
				</form>
			</FormProvider>
		</PricingTabWrapper>
	)
}
