import { useDM } from "@services"
import PropertyInformation from "./propertyInformation"
import { SectionUserDetailsWrapper } from "./styles"
import UserInformation from "./userInformation"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { DMData, DMLiveFeedEventId } from "@store/index"
import { Dispatch, SetStateAction, useEffect } from "react"
import { DMDataReservationRequest } from "@store/DM/DMDataReservationRequest"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
interface IProps {
	setIsPopupOpen: Dispatch<SetStateAction<boolean>>
}
export default function SectionUserDetails({ setIsPopupOpen }: IProps) {
	const setDMData = useSetRecoilState(DMData)
	const { GetPropertyDetailsQuery } = useDM()
	const DMLiveFeedEventIdValue = useRecoilValue(DMLiveFeedEventId)
	const DMDataReservationRequestState = useSetRecoilState(
		DMDataReservationRequest
	)

	const { data } = GetPropertyDetailsQuery(
		{
			eventId: DMLiveFeedEventIdValue?.live_feed_event_id!,
		},
		{ enabled: DMLiveFeedEventIdValue?.live_feed_event_id !== undefined }
	)

	useEffect(() => {
		if (data?.data?.result) {
			setDMData({
				guest_name: `${data?.data?.result?.reservation.guest_first_name} ${data?.data?.result?.reservation.guest_last_name}`,
				checkin_date: dayjs(data?.data?.result.reservation.start_date).format(
					"YYYY-MM-DD"
				),
				currency: data?.data?.result?.reservation.host_currency,
				listing_name: data?.data?.result?.propertyName,
				property_id: data?.data?.result?.listingId,
				room_type_id: "",
			})
			DMDataReservationRequestState({
				channals: data.data.result.ota,
				customer: {
					name: data?.data?.result.customer.name,
					phone: data?.data?.result.customer.phone,
					surname: data?.data?.result.customer.phone,
				},
				reservation: {
					start_date: data?.data?.result.reservation.check_in_datetime,
					end_date: data?.data?.result.reservation.check_out_datetime,
					nightPrice:
						data?.data?.result.reservation.expected_payout_amount_accurate,
					totalPrice:
						data?.data?.result.reservation.listing_base_price_accurate,
					property_id: data?.data?.result?.reservationID,
					propertyName: data?.data?.result?.propertyName,
					time_zone: data?.data?.result?.reservation.time_zone,
					status_type: data?.data?.result?.reservation.status_type,
				},
			})
		} else {
			DMDataReservationRequestState(undefined)
		}
	}, [data, DMLiveFeedEventIdValue])
	const {t}=useTranslation();

	return (
		<SectionUserDetailsWrapper>
			<div className='containerHeader'>
				<p>{t('DM.Details')}</p>
			</div>
			<div className='containerInfoData'>
				<UserInformation />
				{DMLiveFeedEventIdValue?.live_feed_event_id && (
					<PropertyInformation setIsPopupOpen={setIsPopupOpen} />
				)}
			</div>
		</SectionUserDetailsWrapper>
	)
}
