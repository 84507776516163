import { isNumber, minusPadding } from '@utils';
import { Box, Container, Switch, SwitchProps, TextField, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Swiper as SwiperClass } from 'swiper/types';
import { A11y } from 'swiper/modules';

import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { styled, useTheme } from '@mui/material/styles';
import { useSubscription } from '@services';
import { UseQueryResult } from '@tanstack/react-query';
import { ISubscriptionPlan } from '@interfaces';
import { JoinCard } from './JoinCard';
import { AddOutlined, Remove } from '@mui/icons-material';
import { Conditional, Else, If } from '@components';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 1,
      },
    },

    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 1,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
const Join = ({ IsLanding = true, OPlanSelected = (i: string) => {} }) => {
  const [swiper, setSwiperInstance] = useState<SwiperClass>();
  const [selectedPeriod, setSelectedPeriod] = useState<'YEARLY' | 'MONTHLY'>('MONTHLY');
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [numberOfProperty, setNumberOfProperty] = useState(0);
  const { allSubscriptionPlans, subscriptionFeatures } = useSubscription();

  const SubscriptionQuery = allSubscriptionPlans();
  const { data: features } = subscriptionFeatures();

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dir = useMemo(() => {
    return language === 'ar' ? 'rtl' : 'ltr';
  }, [language]);

  const selectedIndex: number | undefined = useMemo(() => {
    if (numberOfProperty === 0) {
      if (!mdUp) {
        swiper?.enable();
      }
      return undefined;
    }
    if (numberOfProperty < 4) {
      if (!mdUp) {
        swiper?.enable();

        swiper?.slideTo(0);
        swiper?.disable();
      }
      return 0;
    }
    if (numberOfProperty <= 20) {
      if (!mdUp) {
        swiper?.enable();
        swiper?.slideTo(1);
        swiper?.disable();
      }
      return 1;
    }
    if (!mdUp) {
      swiper?.enable();
      swiper?.slideTo(2);
      swiper?.disable();
    }
    return 2;
  }, [numberOfProperty]);

  useEffect(() => {
    swiper?.changeLanguageDirection(language === 'ar' ? 'rtl' : 'ltr');
  }, [language]);
  const navigate = useNavigate();

  if (!SubscriptionQuery.every((q: UseQueryResult<ISubscriptionPlan[]>) => q.isSuccess)) {
    return (
      <Box
        id='register'
        sx={{
          ...minusPadding,
          py: 3,
          mt: -2,
          pt: 8,
          position: 'relative',
          width: !IsLanding ? 100 : '100vw',
          bgcolor: '#FFF',
        }}
      ></Box>
    );
  }
  return (
    <Box
      id='register'
      sx={{
        mx: {
          xs: -4,
          md: -6,
        },
        py: 3,
        mt: -2,
        pt: !IsLanding ? 0 : 8,
        position: 'relative',
        width: !IsLanding ? '100%' : '100vw',

        bgcolor: '#FFF',
      }}
    >
      <Box
        sx={{
          px: {
            xs: 4,
            md: 6,
          },
        }}
      >
        <Conditional>
          <If isTrue={IsLanding}>
            <Typography textAlign={'center'} fontWeight={'bold'} mb={4} fontSize={'30px'}>
              {t('FREE_TRAIL')}{' '}
              <Typography
                sx={{
                  display: 'inline-block',
                  position: 'relative',
                  textWrap: 'nowrap',
                }}
                fontWeight={'bold'}
                color={'primary'}
                fontSize={'30px'}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    insetInline: 0,
                    bottom: '-6px',
                    rotate: '-10deg',
                    height: '12px',
                    border: 'solid 5px #5B3FFF',
                    borderColor: '#5B3FFF transparent transparent transparent',
                    borderRadius: ' 40%/10px 12px 0 0',
                  }}
                ></Box>
                {t('FREE_TRAIL_DAYS')}{' '}
              </Typography>{' '}
              {t('FREE_TRAIL_')}
            </Typography>
          </If>
        </Conditional>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 3, alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',

              '.swiper-slide': { border: '1px solid red !important' },
              maxWidth: '100vw',
              mb: 2,
              gap: 2,
            }}
          >
            <Typography
              sx={{
                position: 'relative',
                '&:after': {
                  position: 'absolute',
                  insetBlockStart: '-2px',
                  insetInlineStart: language === 'ar' ? '-72px' : '-80px',
                  px: 1,
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '10px',
                  borderRadius: '20px',
                  height: '26px',
                  display: 'flex',
                  pt: 0.25,
                  alignItems: 'center',
                  background: 'linear-gradient(90deg, rgba(35,111,167,1) 0%, rgba(80,64,231,1) 100%)',
                  content: `"${t('DISCOUNT', { percentage: '11%' })}"`,
                },
              }}
            >
              {t('YEARLY')}
            </Typography>
            <IOSSwitch
              checked={selectedPeriod === (language === 'ar' ? 'YEARLY' : 'MONTHLY')}
              onChange={() => setSelectedPeriod(selectedPeriod === 'MONTHLY' ? 'YEARLY' : 'MONTHLY')}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>{t('MONTHLY')}</Typography>
          </Box>
          <Typography>{t('PROPERTIES_MANAGEMENT.NUM_OF_PROPERTY')}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, alignItems: 'center', marginBottom: 3 }}>
            <Remove
              onClick={() => {
                if (numberOfProperty > 0) {
                  setNumberOfProperty((prev) => prev - 1);
                }
              }}
              sx={{
                color: numberOfProperty > 0 ? 'black' : '#5b3fff',
                borderRadius: '8px',
                opacity: numberOfProperty > 0 ? '1' : '0.3',
                border: '1px solid black',
                p: 1,
                width: '40px',
                height: '40px',
                cursor: numberOfProperty > 0 ? 'pointer' : 'auto',
              }}
            />
            <TextField
              sx={{
                width: '100px',
                '.MuiInputBase-input': { textAlign: 'center', fontSize: '32px', color: '#5b3fff' },
                '.MuiInputBase-root:before': {
                  display: 'none',
                },
                '.MuiInputBase-root:after': {
                  display: 'none',
                },
              }}
              variant={'standard'}
              fullWidth
              value={numberOfProperty}
              onChange={(event) => {
                const val: number = Number(event?.target?.value ?? 0);
                if (!isNaN(val)) {
                  setNumberOfProperty(val);
                }
              }}
            ></TextField>
            <AddOutlined
              onClick={() => {
                setNumberOfProperty((prev) => prev + 1);
              }}
              sx={{
                color: 'black',
                borderRadius: '8px',
                border: '1px solid black',
                p: 1,
                width: '40px',
                height: '40px',
                cursor: 'pointer',
              }}
            />
          </Box>
        </Box>
      </Box>
      <Conditional>
        <If isTrue={mdUp}>
          <Container
            sx={{
              px: {
                xs: 4,
                md: 6,
              },
            }}
            maxWidth={'md'}
          >
            <div className={'grid grid-cols-3 gap-8'}>
              {SubscriptionQuery?.filter((d) => d?.isSuccess)?.reverse()?.map(({ data }, index) => {
                const monthly = data![0];
                const yearly = data![1];
                const selected = selectedPeriod === 'MONTHLY' ? monthly : yearly;
                return (
                  <JoinCard
                    planName={selected.planDescriptionAr}
                    discount={selected.discount}
                    description={t('MANAGE_PLAN.PROPERTY_COUNT_', {
                      properties: selected.numberOfProperties?.toString(),
                    })}
                    key={selected.subscriptionPlanId}
                    price={selected.amount}
                    disabled={isNumber(selectedIndex) ? selectedIndex != index : false}
                    best={selectedPeriod === 'YEARLY'}
                    oldPrice={selectedPeriod === 'YEARLY' ? monthly.amount : undefined}
                    onClick={() => {
                      !IsLanding
                        ? OPlanSelected(selected.subscriptionTypeId.toString())
                        :true ?  window.open("https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0jfgD0WEizdTsurS3cNdfPFIuzm-3wqSogMkuzC9y-Dw99A8a7rYZF5OmwL8jdgfPeI2y1JydD", "_blank") : navigate({
                            pathname: '/register',
                          });
                    }}
                    noPadding
                    features={features ?? []}
                  />
                );
              })}
            </div>
          </Container>
        </If>
        <Else>
          <Container maxWidth={'xs'} sx={{ px: 0 }}>
            <Swiper dir={dir} modules={[A11y]} slidesPerView={1} onSwiper={(swiper) => setSwiperInstance(swiper)}>
              {SubscriptionQuery?.filter((d) => d.isSuccess)?.map(({ data }, index) => {
                const monthly = data![0];
                const yearly = data![1];
                const selected = selectedPeriod === 'MONTHLY' ? monthly : yearly;

                return (
                  <SwiperSlide key={selected.subscriptionPlanId}>
                    <JoinCard
                      planName={selected.planDescriptionAr}
                      discount={selected.discount}
                      price={selected.amount}
                      description={t('MANAGE_PLAN.PROPERTY_COUNT_', {
                        properties: selected.numberOfProperties?.toString(),
                      })}
                      disabled={isNumber(selectedIndex) ? selectedIndex != index : false}
                      best={selectedPeriod === 'YEARLY'}
                      oldPrice={selectedPeriod === 'YEARLY' ? monthly.amount : undefined}
                      onClick={() => {
                        !IsLanding
                          ? OPlanSelected(selected.subscriptionTypeId.toString())
                          : navigate({
                              pathname: '/register',
                            });
                      }}
                      features={features ?? []}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Container>
        </Else>
      </Conditional>
    </Box>
  );
};
export default Join;
