import styled from "styled-components"

export const DatePickerWrapper = styled.div<{
	$islabelVisible: boolean
	$direction?: string
	$isError: boolean
	$language: "ar" | "en"
}>`
	label {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		p {
			font-weight: 700;
			text-align: ${(props) => (props.$direction === "ltr" ? "end" : "start")};
			color: #435363;
			visibility: ${(props) => (props.$islabelVisible ? "visible" : "hidden")};
			font-size: 15px;
			margin: 0;
			margin-bottom: 4px;
			text-transform: capitalize;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: flex;
			flex-direction: ${(props) =>
				props.$direction === "ltr" ? "row-reverse" : "row"};
			span {
				color: #d70015;
				margin-inline: 4px;
			}
		}
		.MuiInputBase-root {
			padding: 0;
			height: 45px;
			font-size: 15px;
			color: #000;
			background-color: #fff;
			outline: none;
			border: 1px solid #000;
			font-size: 15px;
			color: ${(props) => (props.$isError ? "#EF4444" : "#000")};
			background-color: #fff;
			padding: 6px 25px 1px 0;
			border: 1px solid ${(props) => (props.$isError ? "#EF4444" : "#E5E7EB")};
			border-radius: 12px;
			outline: none;
			&:focus {
				border: 1px solid ${(props) => (props.$isError ? "#EF4444" : "#E5E7EB")};
			}
			svg {
				color: #435363;
				width: 18px;
				height: 18px;
			}
			input {
				padding-block: 0;
			}
			> div {
				button {
					padding: 0;
				}
			}
			fieldset {
				border: none;
			}
		}
	}
	.ErrorMessageStyle {
		font-size: 13px;
		font-weight: 400;
		line-height: 22.4px;
		color: #ef4444;
		height: 22px;
		p {
			margin: 0;
		}
	}
`
