import { FC, useContext, useMemo, useState } from 'react';
import { Box, Button, MenuItem, Select, Typography, TextField, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingWrapper } from '@components';

import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { useAdvanceSettings, useProperty } from '@services';
import { ToastMessageContext } from '@context';
import { disabledFeatures } from '@utils';

interface IForm {
  property?: string;
  // channel?: number;
  from?: string;
  to?: string;
  minStay?: string;
  maxStay?: string;
  notice?: string;
}
const TabTwo: FC<{
  handleClose: () => void;
}> = ({ handleClose }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { airBnbMinStayMutate, airBnbUpdateAvailabilityMutate } = useAdvanceSettings();
  const { showError, showSuccess } = useContext(ToastMessageContext);
  const { mutate: MutateRules, isPending: isUpdatingRules } = airBnbUpdateAvailabilityMutate({
    onSuccess: () => {
      handleClose();
      showSuccess(t('CALENDAR.MIN_STAY_SUCCESS'));
    },
    onError: () => showError(t('ERRORS.ERROR_500_MESSAGE')),
  });
  const { mutate, isPending: isUpdatingMinStay } = airBnbMinStayMutate({
    onSuccess: () => {
      handleClose();
      showSuccess(t('CALENDAR.MIN_STAY_SUCCESS'));
    },
    onError: () => showError(t('ERRORS.ERROR_500_MESSAGE')),
  });

  const { control, handleSubmit, watch, getValues } = useForm<IForm>();

  const { getManagementListQuery } = useProperty();

  // const channel = watch('channel');
  const propertyId = watch('property');
  const { data: managementList } = getManagementListQuery();

  const selectedProperty = useMemo(() => {
    return managementList?.find((i: any) => i?.propertyId === propertyId);
  }, [propertyId]);
  const onSubmit = (values: any) => {
    // mutate({
    //   listing_id: selectedProperty!.propertyId.toString(),
    //   date_from: values?.from?.format('YYYY-MM-DD'),
    //   date_to: values?.to?.format('YYYY-MM-DD'),
    //   min_stay: values.minStay,
    //   rate: '',
    // });
    if (isPorpertyLinkedWithAirbnb) {
      if(! getValues().maxStay && ! getValues().minStay  && !getValues().notice ){
        return 
      }
      MutateRules({
        listing_id: selectedProperty!.propertyId.toString(),
        default_max_nights: getValues().maxStay ||null,
        default_min_nights: getValues().minStay || null,
        max_days_notice: getValues().notice || null,
        propertyName: selectedProperty?.propertyName
      })
    }

  };

  const isPorpertyLinkedWithAirbnb =disabledFeatures.rules ?false : (!propertyId || selectedProperty?.airbnbUnitId?.length) ?? false 
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingWrapper loading={isUpdatingRules || isUpdatingMinStay} >


        <Grid container gap={2} className={'py-4'}>


          <Grid item xs={12}>
            <Controller
              name='property'
              defaultValue={''}
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Typography mb={1}>
                    <span className='font-semibold'>{t('CALENDAR.CHOOSEـPROPERTY')}</span>
                  </Typography>

                  <Select
                    onChange={onChange}
                    fullWidth
                    error={Boolean(error)}
                    id='property'
                    placeholder={t('CALENDAR.CHOOSE')}
                    className='!rounded-3xl'
                    value={value}
                  >
                    {managementList?.filter((x:any)=>x?.airbnbUnitId)?.map((property: any) => (
                      <MenuItem key={property?.propertyId} value={property.propertyId}>
                        {property?.propertyName}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </Grid>
          <Typography variant='h6'>{t('CALENDAR.PERIOD')}</Typography>

          {/* <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Controller
                  name='from'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <Typography mb={1}>
                        <span className='font-semibold'>{t('CALENDAR.FROM')}</span>
                      </Typography>

                      <DatePicker

                        onChange={onChange}
                        value={value}
                        slotProps={{
                          textField: {
                            error: Boolean(error),
                            InputProps: { sx: { borderRadius: '1.5rem' } },
                            dir: false ? 'rtl' : 'ltr',
                          },
                        }}
                        format={'YYYY-MM-DD'}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name='to'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <Typography mb={1}>
                        <span className='font-semibold'>{t('CALENDAR.TO')}</span>
                      </Typography>

                      <DatePicker
                        onChange={onChange}
                        value={value}
                        className='!rounded-3xl'
                        slotProps={{
                          textField: {
                            error: Boolean(error),
                            InputProps: { sx: { borderRadius: '1.5rem' } },
                            // dir: language == 'ar' ? 'rtl' : 'ltr',
                          },
                        }}
                        format={'YYYY-MM-DD'}
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </Grid> */}

          {!isPorpertyLinkedWithAirbnb ? <Grid item xs={12}>
            <Controller
              name='minStay'
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Typography mb={1}>
                    <span className='font-semibold'>{t('CALENDAR.MIN_DAYS')}</span>
                  </Typography>

                  <TextField
                    onChange={(e) => {
                      const val: number = Number(e?.target?.value);
                      if (!isNaN(val)) {
                        onChange(e);
                      }
                    }}
                    type={'number'}
                    error={Boolean(error)}
                    helperText={t('CALENDAR.MIN_DAYS_HINT')}
                    InputProps={{ sx: { borderRadius: '1.5rem' } }}
                    value={value}
                    fullWidth
                    FormHelperTextProps={{
                      style: {
                        textAlign: language == 'ar' ? 'right' : 'left',
                        lineHeight: '17px'
                      }
                    }}
                  />
                </>
              )}
            />
          </Grid> : null}
          {isPorpertyLinkedWithAirbnb ? <Box sx={{ display: 'flex' ,alignItems:'center' }} >
            <Box sx={{ flex: 1}} >
              <Typography mb={1} fontSize={14}  >
                <span className='font-semibold'>{t('CALENDAR.MIN_STAY_DAYS')}</span>
              </Typography>
              <Controller
                name='minStay'
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>


                    <TextField
                      onChange={(e) => {
                        const val: number = Number(e?.target?.value);
                        if (!isNaN(val)) {
                          onChange(e);
                        }
                      }}
                      type={'number'}
                      error={Boolean(error)}
                      placeholder={t('CALENDAR.ENTER_NUMBER')}
                      FormHelperTextProps={{
                        style: {
                          textAlign: language == 'ar' ? 'right' : 'left',
                          lineHeight: '17px'
                        }
                      }}
                      helperText={t('UN_BLOCKING.MIN_HINT')}
                      InputProps={{ sx: { borderRadius: '1.5rem' } }}
                      value={value}
                      fullWidth
                    />
                  </>
                )}
              />
            </Box>
                      <Box width={10} />
            <Box sx={{ flex: 1 }} >
              <Typography mb={1} fontSize={14}  >
                <span className='font-semibold'>{t('CALENDAR.MAX_DAYS')}</span>
              </Typography>
              <Controller
                name='maxStay'
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>


                    <TextField
                      onChange={(e) => {
                        const val: number = Number(e?.target?.value);
                        if (!isNaN(val)) {
                          onChange(e);
                        }
                      }}
                      type={'number'}
                      error={Boolean(error)}
                      placeholder={t('CALENDAR.ENTER_NUMBER')}
                      helperText={t('UN_BLOCKING.MAX_HINT')}
                      InputProps={{ sx: { borderRadius: '1.5rem' } }}
                      value={value}
                      FormHelperTextProps={{
                        style: {
                          textAlign: language == 'ar' ? 'right' : 'left',
                          lineHeight: '17px'
                        }
                      }}
                      fullWidth
                    />
                  </>
                )}
              />
            </Box>
          </Box> : null}
          {isPorpertyLinkedWithAirbnb ? <Grid item xs={12}>
            <Controller
              name='notice'
              control={control}
              rules={{
                required: false,
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Typography mb={1}>
                    <span className='font-semibold'>{t('CALENDAR.NOTICE_PERIOD')}</span>
                  </Typography>
                  <Select
                    onChange={onChange}
                    fullWidth
                    error={Boolean(error)}
                    id='property'
                    placeholder={t('CALENDAR.CHOOSE')}
                    className='!rounded-3xl'
                    value={value}
                  >
                    <MenuItem value={1}>
                      {t('CALENDAR.SAME_DAY')}
                    </MenuItem>
                    <MenuItem value={2}>
                      {t('CALENDAR.ONE_DAY')}
                    </MenuItem>
                    <MenuItem value={2}>
                      {t('CALENDAR.TWO_DAYS')}
                    </MenuItem>
 
                  </Select>
                  <Typography sx={{color:'rgba(142, 152, 161, 1)',fontSize:'14px',lineHeight:'19px',mt:0.5}} >
                    {t('CALENDAR.NOTICE_DAYS')}
                  </Typography>
                  {/* <TextField
                  onChange={(e) => {
                    const val: number = Number(e?.target?.value);
                    if (!isNaN(val)) {
                      onChange(e);
                    }
                  }}
                  type={'number'}
                  error={Boolean(error)}
                  placeholder={t('CALENDARENTER_NUMBER')}
                  helperText={t('CALENDAR.NOTICE_DETAILS')}
                  InputProps={{ sx: { borderRadius: '1.5rem' } }}
                  value={value}
                  fullWidth
                  FormHelperTextProps={{
                    style:{
                      textAlign: language=='ar' ? 'right':'left',
                      lineHeight:'17px'
                    }
                  }}
                /> */}
                </>
              )}
            />
          </Grid> : null}



          <Grid xs={12}>
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <Button type={'submit'} variant={'contained'} fullWidth>
                {t('SAVE')}
              </Button>
              <Button
                variant='outlined'
                fullWidth
                // size={'large'}
                color={'info'}
                onClick={handleClose}
              >
                {t('GENERAL.CANCEL_ALT')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </LoadingWrapper>
    </form>
  );
};
export { TabTwo };
