import { FC, useContext, useEffect } from 'react';
import { useProperty } from '@services';
import { useTranslation } from 'react-i18next';
import { ToastMessageContext } from '@context';
import AirbnbIcon from '@assets/svg/airbnb-icon.svg?react';
import LinkingComponent from './LinkingComponent';
import LinkError from './LinkError';

const AirBnbRedirectStep: FC<{ reset: (close: boolean) => void }> = ({ reset }) => {
  const { airbnbCreateGroupAndPropertyMutate } = useProperty();
  const { t } = useTranslation();
  const { showError } = useContext(ToastMessageContext);

  const { mutate: airbnbCreateGroupAndProperty, isError } = airbnbCreateGroupAndPropertyMutate({
    onSuccess: () => {},
    onError: () => {
      showError(t('ERRORS.SOMETHING_WENT_WRONG'));
    },
  });

  useEffect(() => {
    airbnbCreateGroupAndProperty();
  }, []);

  if (isError) {
    return <LinkError reset={reset} channel={t('AIRBNB')} />;
  }
  return <LinkingComponent channelIcon={<AirbnbIcon />} />;
};
export default AirBnbRedirectStep;
