import { Helmet } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { FC, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { IGuardProps } from '@interfaces';
import { useAuth } from '@services';
import { InActiveUserModal } from '@components';

/**
 * used for private pages to protect and add helmet
 */
const AuthGuard: FC<IGuardProps> = ({ children, titleKey, allowInactive = false }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { showInactiveModal } = useAuth();

  useEffect(() => {
    const interval = setInterval(() => {
      const expiry = localStorage.getItem('exp');
      const expired = dayjs(Number(expiry)).isBefore(dayjs());
      if (expired) {
        localStorage.clear();
        navigate('/login');
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  if (!localStorage.token) {
    return <Navigate to={'/login'} replace state={{ pathname }} />;
  }
  const showSubscriptionModal = showInactiveModal() && !allowInactive; 
  return (
    <>
      <Helmet key={titleKey} >
        <meta charSet='utf-8' />
        <meta name="title" content="Default Title" data-react-helmet="true" />
        {titleKey && <title  >{t(titleKey)}</title>}
      </Helmet>
      <ErrorBoundary
        fallback={
          <div
            className={'flex-1  bg-white m-8 p-4 flex items-center justify-center rounded-xl font-bold text-primary'}
          >
            {t('ERRORS.SOMETHING_WENT_WRONG')}
          </div>
        }
      >
        {children}
        
      </ErrorBoundary>
    </>
  );
};
export default AuthGuard;
