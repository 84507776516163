
import { FC, useEffect, useState } from 'react';
import { Box, Typography, Button, TextField } from '@mui/material';

import { LoadingWrapper } from '@components';
import { useProperty } from '@services';
import HotelIdIcon from '@assets/svg/hotelIdIcon.svg?react';
import { useTranslation } from 'react-i18next';
 
//12534635
const BookingHotelId = ({ onNext = () => { }, onSUccessHotel = (n: any) => { } }) => {
    const [value, setValue] = useState('')
    const { checkBookingConnectionQuery } = useProperty();
    const { t } = useTranslation();

    const { isError, isPending, mutate, data,reset } = checkBookingConnectionQuery({
        onSuccess:()=>{},
        onError:()=>{}
    }) 
    const [isValidHotelId, setIsValidHotelId] = useState(true)
  
  

    useEffect(() => {
      if (data?.data?.success) {
        onNext()
        onSUccessHotel(value)
      }
    }, [data])
  
    return (
  
  
      <LoadingWrapper loading={isPending} >
  
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, height: '70vh', }} >
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }} >
            <Typography sx={{
              fontWeight: 700,
              fontSize: '14px',
              lineHeight: '24px',
              mt: 6,
              mx: 1,
              mb: 0.5,
              color:'rgba(51, 24, 96, 1)'
  
            }} >
{t('BOOKING.HOTEL_ID')}
              <Box
                component="span"
                sx={{
                  color: 'red'
                }}
              >*</Box >
            </Typography>
            <TextField
              sx={isError ? _inputError :_inputStyle}
              onChange={e => {
                setValue(e.target.value)
                reset() 
              }}
              name='email'
              value={value ?? ''}
              placeholder={''}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
           
          <Box sx={{display:'flex',justifyContent:'flex-end',mt:3}} >
          <HotelIdIcon style={{
                width:'487px',
                height:'175px',
                
            }} />
          </Box>
  
          </Box>
          <Box sx={{ alignItems: 'center', }}>
            <Button  size={'small'} variant={'contained'} onClick={() =>mutate(value)} disabled={!value?.length}>
              {t('BOOKING.NEXT')}
            </Button>
          </Box>
  
        </Box>
      </LoadingWrapper>
  
    )
  }
  export default BookingHotelId
  const _inputStyle = {
    border: ' 1px solid rgba(234, 237, 241, 1)', 
   borderRadius: '4px',
    width: '337px',
    height: '47px',
    px: 2,
    pb: -2,
    '& .MuiInput-underline:after': {
      borderBottomColor: 'yellow',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        border: '0px',
      },
    },
  }
  const _inputError = {
    ..._inputStyle,
    border: ' 1.5px solid rgba(215, 0, 21, 1)', 

  }