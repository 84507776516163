import styled from "styled-components"

export const ReservationDetailsWrapper = styled.div<{
	status: "yellow" | "gray" | "green"
}>`
	.containerHeader {
		p {
			margin: 0;
			color: #261b6a;
			font-size: 18px;
			font-weight: 700;
			line-height: 21.6px;
		}
	}
	.channalAndStatus {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 16px;
		border-bottom: 1px solid #eaeafd;
		padding-bottom: 12px;
		margin-bottom: 12px;
		.channal {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 8px;
			> div {
				width: 30px;
				height: 30px;
				border-radius: 50px;
				overflow: hidden;
				svg {
					width: 100%;
					height: 100%;
				}
			}
			p {
				margin: 0;
				color: #435363;
				font-size: 18px;
				font-weight: 400;
			}
		}
		.status {
			padding: 10px 12px;
			border-radius: 50px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 6px;
			background-color: ${(props) =>
				props.status === "yellow"
					? "#FFF7E1"
					: props.status === "gray"
						? "#E2E2E2"
						: props.status === "green"
							? "#D5F7D8"
							: "#FFF7E1"};
			border: ${(props) =>
				props.status === "yellow"
					? "1px solid #FFE9A9"
					: props.status === "gray"
						? "1px solid #C4C4C4"
						: props.status === "green"
							? "1px solid #B6E5BB"
							: "1px solid #FFE9A9"};
			span {
				color: #435363;
				font-size: 14px;
				font-weight: 400;
				line-height: 16.8px;

				&:first-child {
					border-radius: 50px;
					background-color: ${(props) =>
						props.status === "yellow"
							? "#FFE9A9"
							: props.status === "gray"
								? "#C4C4C4"
								: props.status === "green"
									? "#B6E5BB"
									: "#FFE9A9"};
					width: 9px;
					height: 9px;
				}
			}
		}
	}

	.infoSection {
		border-bottom: 1px solid #eaeafd;
		padding-bottom: 12px;
		margin-bottom: 12px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		p {
			margin: 0;
		}
		.iconSection {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: #5b3fff;
			svg {
				width: 20px;
				height: 20px;
			}
		}
		.titleSection {
			margin-top: 8px;
			p {
				font-size: 12px;
				font-weight: 400;
				line-height: 14.4px;
				color: #8e98a1;
			}
		}
		.detailsSection {
			margin-top: 16px;
			p {
				font-size: 14px;
				font-weight: 400;
				line-height: 16.8px;
				color: #0f172a;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 5px;
				.handleCopyClick {
					cursor: copy;
				}
				&:last-child {
					margin-top: 16px;
					span {
						&:nth-child(2) {
							font-size: 14px;
							font-weight: 400;
							line-height: 16.8px;
							color: #5b3fff;
						}
					}
				}
			}
		}
	}

	.dateAndTimeSection {
		border-bottom: 1px solid #eaeafd;
		padding-bottom: 12px;
		margin-bottom: 12px;
		p {
			margin: 0;
		}
		.iconSection {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: #5b3fff;
			svg {
				width: 20px;
				height: 20px;
			}
		}
		.titleSection {
			margin-top: 8px;
			p {
				font-size: 12px;
				font-weight: 400;
				line-height: 14.4px;
				color: #8e98a1;
			}
		}
		.dateAndTime {
			margin-top: 16px;
			.cotainer {
				border-right: 2px solid #5b3fff;
				padding-right: 6px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 10px;
				p {
					font-size: 12px;
					font-weight: 500;
					line-height: 14.4px;
					color: #0f172a;
				}
				.time {
					border: 1px solid #e5e7eb;
					padding: 11px 14px;
					border-radius: 50px;
					color: #8e98a1;
					font-size: 12px;
					font-weight: 500;
					line-height: 24px;
				}
			}
		}
	}
	.pricingSection {
		.iconSection {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: #5b3fff;
			svg {
				width: 20px;
				height: 20px;
			}
		}
		.titleSection {
			margin-top: 8px;
			p {
				font-size: 12px;
				font-weight: 400;
				line-height: 14.4px;
				color: #8e98a1;
			}
		}
		.detailsSection {
			margin-top: 16px;
			p {
				font-size: 14px;
				font-weight: 400;
				line-height: 16.8px;
				color: #0f172a;
				&:last-child {
					margin-top: 16px;
					span {
						&:nth-child(2) {
							font-size: 14px;
							font-weight: 400;
							line-height: 16.8px;
							color: #5b3fff;
						}
					}
				}
			}
		}
		p {
			margin: 0;
		}
	}
`
