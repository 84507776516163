import { useMemo } from "react";
import { ChatMessagesWrapper } from "./styles";
import dayjs from "dayjs";
import { Box } from "@mui/material";

interface IProps {
  getALLChat: {
    message: string;
    sender: "guest" | "property" | "system";
    time: string;
    id: string;
  }[];
}
export default function ChatMessages({ getALLChat }: IProps) {
  const messages = useMemo(
    () =>
      getALLChat.reduce((group: any, item) => {
        console.log("item.time ", item.time);
        let date = dayjs(item.time).format("YYYY-MM-DD");

        if (!group[date]) {
          group[date] = [];
        }

        group[date].push(item);

        return group;
      }, {}),
    [getALLChat]
  );
  const lang = localStorage.getItem('i18nextLng');

  return (
    <ChatMessagesWrapper>
      {Object.keys(messages).map((date) => {
        return (
          <div>
            <Box
              sx={{
                textAlign: "center",
                color: "#adbac7",
                fontSize: "14px",
                marginTop: 1,
              }}
            >
              {`${dayjs(date).locale(lang??'ar').format('dddd')}, ${date}`}
            </Box>
            {messages[date].map((item: any) => {
              return (
                <div key={item.id} className={`${item.sender}`}>
                  <div>
                    <p>{item.message}</p>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </ChatMessagesWrapper>
  );
}