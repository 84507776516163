import { Dispatch, FC, SetStateAction } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { platformConfig,disabledFeatures } from '@utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const StepOne: FC<{
  setChannel: Dispatch<SetStateAction<string | undefined>>;
  channel?: string;
  onLink: () => void;
}> = ({ channel, setChannel, onLink }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ flex: 1, pt: 4, pb: 2, display: 'flex', flexDirection: 'column' }}> 
      <Typography color={'#0F172A'} fontSize={'20px'}>
        {t('ADD_PROPERTY.CHOOSE_CHANNEL')}
      </Typography>
      <Typography color={'#8E98A1'} fontSize={'16px'}>
        {t('ADD_PROPERTY.AVAILABLE_CHANNELS')}
      </Typography>

      <Box
        sx={{
          pt: 3,
          display: 'grid',
          gridTemplateColumns: { xs: 'repeat(2,1fr)', sm: 'repeat(3,1fr)' },
          gap: 2,
          marginBottom: 4,
          borderBlockEnd: '1px solid #EAEDF1',
          paddingBottom: 3,
        }}
      >
        <Box
          onClick={() => setChannel('airbnb')}
          sx={{
            aspectRatio: 1,
            border: '2px solid',
            borderColor: channel == 'airbnb' ? '#5B3FFF' : '#EEEBFF',
            backgroundColor: channel == 'airbnb' ? '#EEEBFF' : '#FFF',
            cursor: channel == 'airbnb' ? 'auto' : 'pointer',
            borderRadius: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              svg: {
                width: '48px',
                height: '48px',
              },
            }}
          >
            {platformConfig[0].logo}
          </Box>
          <Typography>{t(platformConfig[0].label)}</Typography>
          {channel == 'airbnb' && <CheckCircleIcon color={'primary'} fontSize={'small'} />}
        </Box>
        <Box
          onClick={() => setChannel('gathern')}
          sx={{
            aspectRatio: 1,
            border: '2px solid',
            borderColor: channel == 'gathern' ? '#5B3FFF' : '#EEEBFF',
            backgroundColor: channel == 'gathern' ? '#EEEBFF' : '#FFF',
            cursor: channel == 'gathern' ? 'auto' : 'pointer',
            borderRadius: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              svg: {
                width: '48px',
                height: '48px',
              },
            }}
          >
            {platformConfig[1].logo}
          </Box>
          <Typography>{t(platformConfig[1].label)}</Typography>
          {channel == 'gathern' && <CheckCircleIcon color={'primary'} fontSize={'small'} />}
        </Box>
       {disabledFeatures.booking ?  null: <Box
          onClick={() => setChannel('booking')}
          sx={{
            aspectRatio: 1,
            border: '2px solid',
            borderColor: channel == 'booking' ? '#5B3FFF' : '#EEEBFF',
            backgroundColor: channel == 'booking' ? '#EEEBFF' : '#FFF',
            cursor: channel == 'gathern' ? 'auto' : 'pointer',
            borderRadius: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              svg: {
                width: '48px',
                height: '48px',
              },
            }}
          >
            {platformConfig[2].logo}
          </Box>
          <Typography>{t(platformConfig[2].label)}</Typography>
          {channel == 'booking' && <CheckCircleIcon color={'primary'} fontSize={'small'} />}
        </Box> } 
      </Box>
      <Box sx={{ mt: 'auto', display: 'flex', gap: 8, alignItems: 'center' }}>
        <Button size={'small'} variant={'contained'} onClick={onLink} disabled={!channel}>
          {t('ADD_PROPERTY.LINK')}
        </Button>
        <Typography color={'#8E98A1'}>{t('ADD_PROPERTY.LINK_HELPER')}</Typography>
      </Box>
    </Box>
  );
};

export default StepOne;
