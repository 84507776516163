import {
	TextareaHTMLAttributes,
	useEffect,
	useState,
} from "react"
import { useFormContext, RegisterOptions } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { TextAreaWrapper } from "./styles"

interface IProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	name: string
	registerOptions?: RegisterOptions
	placeholder?: string
	label?: string
	readonly?: boolean
	labelVisibility?: boolean
	className?: string
	defaultValue?: string | number
	withOutErrorMessage?: boolean
	direction?: "rtl" | "ltr"
	onFocus?: any
	onBlur?: any
}

function TextArea({
	name,
	registerOptions,
	placeholder,
	label,
	readonly,
	labelVisibility = true,
	className,
	disabled,
	defaultValue,
	withOutErrorMessage = false,
	direction,
	onFocus,
	onBlur,
}: IProps) {
	/* ------------------------------- Local State ------------------------------ */
	const [isRequired, setIsRequired] = useState(false)
	/* ---------------------------------- Hooks --------------------------------- */
	const { register, formState } = useFormContext()
	const { errors } = formState

	useEffect(() => {
		if (typeof registerOptions?.required === "object") {
			setIsRequired(registerOptions?.required?.value)
		}
		if (typeof registerOptions?.required === "boolean") {
			setIsRequired(registerOptions.required)
		}
	}, [registerOptions])
	return (
		<TextAreaWrapper
			$islabelVisible={labelVisibility}
			className={className}
			$isError={errors[name] !== undefined ?? false}
			disabled={disabled}
			$direction={direction}
		>
			<label>
				{label && (
					<p>
						{label} {isRequired && <span>*</span>}
					</p>
				)}
				<textarea
					autoComplete='off'
					placeholder={placeholder}
					{...register(name, registerOptions)}
					readOnly={readonly}
					disabled={disabled}
					defaultValue={defaultValue}
					onFocus={onFocus}
					onBlur={onBlur}
				/>
			</label>
			{!withOutErrorMessage && (
				<div className='ErrorMessageStyle'>
					<ErrorMessage
						errors={errors}
						name={name}
						render={({ message }) => <p>{message}</p>}
					/>
				</div>
			)}
		</TextAreaWrapper>
	)
}

export default TextArea
