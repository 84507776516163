import React, { FC, useState } from 'react';
import { Box, Modal, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { modalBoxStyle, modalContainerStyle } from '@utils';
import { useForm } from 'react-hook-form';
import { TabOne } from './tabOne';
import { TabTwo } from './tabTwo';
import { TabThree } from './tabThree';
import TabFour from './tabFour';

const AdvancedSettingsModel: FC<{
  open: boolean;
  handleClose: () => void;
}> = ({ handleClose, open }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [tab, setTab] = useState(0);
  const { control, handleSubmit } = useForm();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={modalContainerStyle}
      aria-labelledby='add-message-modal-title'
      aria-describedby='add-message-modal-description'
    >
      <Box sx={{ ...modalBoxStyle, overflowY: 'auto' }}>
        <Tabs
          value={tab}
          onChange={(_, tab: number) => {
            setTab(tab);
          }}
          aria-label='basic tabs example'
        >
          <Tab label={t('CALENDAR.PRICING')} />
          <Tab label={t('CALENDAR.DAYS_OF_STAY')} />
          <Tab label={t('CALENDAR.AVAILABILITY_SETTINGS')} />
          <Tab label={t('CALENDAR.INSTANT_BOOK_SETTINGS')} />
        </Tabs>
        {tab === 0 && <TabOne handleClose={handleClose} />}
        {tab === 1 && <TabTwo handleClose={handleClose} />}
        {tab === 2 && <TabThree handleClose={handleClose} />}
        {tab === 3 && <TabFour handleClose={handleClose} />}
      </Box>
    </Modal>
  );
};
export default AdvancedSettingsModel;
