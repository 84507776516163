import { FC, useContext, useMemo } from 'react';
import { Box, Button, MenuItem, Select, Typography, Grid, RadioGroup, Radio } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { useAdvanceSettings, useProperty } from '@services';
import { ToastMessageContext } from '@context';
interface IForm {
  property?: string;
  // channel?: number;
  from?: string;
  to?: string;
  availability: number;
}
const TabThree: FC<{
  handleClose: () => void;
}> = ({ handleClose }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { airBnbUpdateAvailabilityMutate } = useAdvanceSettings();
  const { showError, showSuccess } = useContext(ToastMessageContext);

  const { mutate } = airBnbUpdateAvailabilityMutate({
    onSuccess: () => {
      handleClose();
      if (watch('availability') == 0) {
        showSuccess(t('CALENDAR.CLOSE_AVAILABILITY_SUCCESS'));
      } else {
        showSuccess(t('CALENDAR.OPEN_AVAILABILITY_SUCCESS'));
      }
    },
    onError: () => showError(t('ERRORS.ERROR_500_MESSAGE')),
  });

  const { control, handleSubmit, watch } = useForm<IForm>();

  const { getManagementListQuery } = useProperty();
  const propertyId = watch('property');
  const { data: propertyList } = getManagementListQuery();
  const selectedProperty = useMemo(() => {
    return propertyList?.find((i: any) => i?.propertyId === propertyId);
  }, [propertyId]);

  const onSubmit = (values: any) => {
    mutate({
      listing_id: selectedProperty!.propertyId.toString(),
      date_from: values?.from?.format('YYYY-MM-DD'),
      date_to: values?.to?.format('YYYY-MM-DD'),
      min_stay: 0,
      availability: values.availability,
      rate: '',
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container gap={2} className={'py-4'}>
        <Grid item xs={12}>
          <Controller
            name='property'
            defaultValue={''}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Typography mb={1}>
                  <span className='font-semibold'>{t('CALENDAR.CHOOSEـPROPERTY')}</span>
                </Typography>

                <Select
                  onChange={onChange}
                  fullWidth
                  error={Boolean(error)}
                  id='property'
                  placeholder={t('CALENDAR.CHOOSE')}
                  className='!rounded-3xl'
                  value={value}
                >
                  {propertyList?.map((property: any) => (
                    <MenuItem key={property?.propertyId} value={property.propertyId}>
                      {property?.propertyName}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            rules={{
              required: true,
            }}
            name={'availability'}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Typography mb={1}>
                  <span className='font-semibold'>{t('CALENDAR.AVAILABILITY_SETTINGS')}</span>
                </Typography>

                <RadioGroup aria-labelledby='min-stay-radios' name='availability' onChange={onChange} value={value}>
                  <div className='flex items-center cursor-pointer'>
                    <Radio value={0} />
                    <Typography>{`${t('CALENDAR.AVAILABILITY_CLOSE')}`}</Typography>
                  </div>
                  <div className='flex items-center cursor-pointer'>
                    <Radio value={1} />
                    <Typography>{`${t('CALENDAR.AVAILABILITY_OPEN')}`}</Typography>
                  </div>
                </RadioGroup>
              </>
            )}
          />
        </Grid>

        <Typography variant='h6'>{t('CALENDAR.PERIOD')}</Typography>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Controller
                name='from'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <Typography mb={1}>
                      <span className='font-semibold'>{t('CALENDAR.FROM')}</span>
                    </Typography>
                    <DatePicker
                      onChange={onChange}
                      value={value}
                      slotProps={{
                        textField: {
                          error: Boolean(error),
                          InputProps: { sx: { borderRadius: '1.5rem' } },
                          dir: language == 'ar' ? 'rtl' : 'ltr',
                        },
                      }}
                      format={'YYYY-MM-DD'}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name='to'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <Typography mb={1}>
                      <span className='font-semibold'>{t('CALENDAR.TO')}</span>
                    </Typography>

                    <DatePicker
                      onChange={onChange}
                      value={value}
                      className='!rounded-3xl'
                      slotProps={{
                        textField: {
                          error: Boolean(error),
                          InputProps: { sx: { borderRadius: '1.5rem' } },
                          dir: language == 'ar' ? 'rtl' : 'ltr',
                        },
                      }}
                      format={'YYYY-MM-DD'}
                    />
                  </>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Button type={'submit'} variant={'contained'} fullWidth>
              {t('SAVE')}
            </Button>
            <Button variant='outlined' fullWidth color={'info'} onClick={handleClose}>
              {t('GENERAL.CANCEL_ALT')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
export { TabThree };
