import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom"
import {
	Login,
	Register,
	ResetPassword,
	FinanceDetails,
	Finance,
	LandingPage,
	ErrorPage,
	LocksManagement,
	LockDetails,
	PropertiesManagement,
	LocksListing,
	Settings,
	Messaging,
	AfterPayment,
	ManagePlan,
	Dashboard,
	NewPropertyProfile,
	PropertyProfile,
	ControlCenter,
	Subscribe,
	PasswordForget,
	Passwordchange,
	PasswordCode,
	ManualReservationPage,
	ReservationManagementPage,
	GuestManagementPage,
	OwnerManagementPage,
	AddOwner,
	OwnerInsights,
	OwnerEdit,
	DirectMessagesPage,
	NewCalenderPage,
	TaskManagetmenPage
} from "@pages"
import {
	AuthGuard,
	AuthLayout,
	Layout,
	PublicLayout,
	CreateCategoryManagement,
} from "@components"
import { ReservationInfo } from "../pages/ReservationInfo"
import CategoryManagementPage from "../pages/CategoryManagementPage"

export const useRouterLinks = () => {
	const routerObjects: RouteObject[] = [
		{
			path: "/",
			element: (
				<PublicLayout>
					<LandingPage />
				</PublicLayout>
			),
		},
		{
			path: "/reservation-info/:id",
			element: <ReservationInfo />,
		},
		{
			path: "/*",
			element: <Layout />,
			children: [
				{
					path: "control-center",
					element: (
						<AuthGuard titleKey={"CONTROL.TITLE"}>
							<ControlCenter />
						</AuthGuard>
					),
				},
				{
					path: "calendar",
					element: (
						<AuthGuard titleKey={"DASHBOARD.CALENDAR"}>
							<NewCalenderPage />
						</AuthGuard>
					),
				},
				{
					path: "finance",
					children: [
						{
							index: true,
							element: <Finance />,
						},
						{
							path: "accounting",
							element: (
								<AuthGuard titleKey={"FINANCE.TITLE"}>
									<FinanceDetails />
								</AuthGuard>
							),
						},
						{
							path: "accounting/:id",
							element: (
								<AuthGuard titleKey={"FINANCE.TITLE"}>
									<FinanceDetails />
								</AuthGuard>
							),
						},
					],
				},
				{
					path: "manage-plan",
					element: (
						<AuthGuard titleKey={"MANAGE_PLAN.TITLE"}>
							<ManagePlan />
						</AuthGuard>
					),
				},
				{
					path: "v1/meta/airbnb/auth_redirect",
					children: [
						{
							index: true,
							element: (
								<AuthGuard
									allowInactive
									titleKey={"PROPERTIES_MANAGEMENT.TITLE"}
								>
									<PropertiesManagement />
								</AuthGuard>
							),
						},
					],
				},
				{
					path: "properties-management", //https://staging.channex.io/api/v1/meta/airbnb/auth_redirect?success=true&channel_id=f4f7f5b9-0ded-457f-9b4a-0f2399ef85a7&token=
					children: [
						{
							index: true,
							element: (
								<AuthGuard
									allowInactive
									titleKey={"PROPERTIES_MANAGEMENT.TITLE"}
								>
									<PropertiesManagement />
								</AuthGuard>
							),
						},
						{
							path: "v1/meta/airbnb/auth_redirect",
							element: (
								<AuthGuard
									allowInactive
									titleKey={"PROPERTIES_MANAGEMENT.TITLE"}
								>
									<PropertiesManagement />
								</AuthGuard>
							),
						},
						{
							path: "profile/:id/new",
							element: (
								<AuthGuard titleKey={"PROPERTIES_MANAGEMENT.TITLE"}>
									<NewPropertyProfile />
								</AuthGuard>
							),
						},
						{
							path: "profile/:id",
							element: (
								<AuthGuard titleKey={"PROPERTIES_MANAGEMENT.TITLE"}>
									<PropertyProfile />
								</AuthGuard>
							),
						},
					],
				},
				{
					path: "locks-management",
					children: [
						{
							index: true,
							element: <Navigate to={"control"} />,
						},
						{
							path: "control",
							element: (
								<AuthGuard titleKey={"LOCKS.TITLE"}>
									<LocksManagement />
								</AuthGuard>
							),
						},
						{
							path: "control/:id",
							element: (
								<AuthGuard titleKey={"LOCKS.TITLE"}>
									<LockDetails />
								</AuthGuard>
							),
						},
						{
							path: "listing",
							element: (
								<AuthGuard titleKey={"LOCKS.TITLE"}>
									<LocksListing />
								</AuthGuard>
							),
						},
					],
				},
				{
					path: "settings",
					element: (
						<AuthGuard titleKey={"SETTINGS"}>
							<Settings />
						</AuthGuard>
					),
				},
				{
					path: "dashboard",
					element: (
						<AuthGuard titleKey={"DASHBOARD.TITLE"}>
							<Dashboard />
						</AuthGuard>
					),
				},
				{
					path: "messaging",
					element: (
						<AuthGuard titleKey={"MESSAGING.TITLE"}>
							<Messaging />
						</AuthGuard>
					),
				},
				{
					path: "manual-reservation",
					element: (
						<div>
							<ManualReservationPage />
						</div>
					),
				},
				{
					path: "reservation-management",
					element: (
						<div>
							<ReservationManagementPage />
						</div>
					),
				},
				{
					path: "guest-management",
					element: (
						<div>
							<GuestManagementPage />
						</div>
					),
				},
				{
					path: "owner-management",
					element: (
						<div>
							<OwnerManagementPage />
						</div>
					),
				},
				{
					path: "add-owner",
					element: (
						<div>
							<AddOwner />
						</div>
					),
				},
				{
					path: "owner-insights/:id",
					element: (
						<div>
							<OwnerInsights />
						</div>
					),
				},
				{
					path: "owner-edit/:id",
					element: (
						<div>
							<OwnerEdit />
						</div>
					),
				},
				{
					path: "category-management",
					element: (
						<div>
							<CategoryManagementPage />
						</div>
					),
				},
				{
					path: "create-category-management",
					element: <CreateCategoryManagement />,
				},
				{
					path: "direct-messages",
					element: <DirectMessagesPage />,
				},
				{
					path: "task-management",
					element: <TaskManagetmenPage />,
				},
				{
					path: "*",
					element: <Navigate to={"/404"} />,
				},
			],
		},
		{
			path: "/login",
			element: <Login />,
		},
		{
			path: "/forget-password",
			element: <PasswordForget />,
		},
		{
			path: "/forget-password-code/:id",
			element: <PasswordCode />,
		},
		{
			path: "/change-password",
			element: <Passwordchange />,
		},
		{
			path: "/payment",
			element: (
				<AuthLayout>
					<AfterPayment type={"loading"} />
				</AuthLayout>
			),
		},
		{
			path: "/register",
			element: (
				<AuthLayout>
					<Register />
				</AuthLayout>
			),
		},

		{
			path: "/success-payment",
			element: (
				<AuthLayout>
					<AfterPayment type={"success"} />
				</AuthLayout>
			),
		},
		{
			path: "/failed-payment",
			element: (
				<AuthLayout>
					<AfterPayment type={"fail"} />
				</AuthLayout>
			),
		},

		{
			path: "/resetpassword",
			element: (
				<AuthLayout logo>
					<ResetPassword />
				</AuthLayout>
			),
		},
		{
			path: "/subscribe",
			element: (
				<div>
					<Subscribe />
				</div>
			),
		},
		{
			path: "/404",
			element: (
				<div className={"min-h-screen flex"}>
					<ErrorPage code={404} />
				</div>
			),
		},
	]
	const router = createBrowserRouter(routerObjects)

	return { router }
}
