// @ts-nocheck

import { FC, useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
const PieChart: FC<{ id?: string; data?: { category: string; value: number }[] }> = ({
  id = 'pieChart',
  data = [],
}) => {
  useLayoutEffect(() => {
    const root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)]);
    root._logo.dispose();

    // Create chart

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        endAngle: 270,
        layout: root.verticalLayout,
        innerRadius: am5.percent(75),
      }),
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        endAngle: 270,
        fill: 'fill',
      }),
    );

    series.set(
      'colors',
      am5.ColorSet.new(root, {
        colors: [am5.color('#ff5a5f'), am5.color('#5a409b')],
      }),
    );
    series.labels.template.setAll({
      visible: false,
      opacity: 0,
      disabled: true,
      active: false,

      forceHidden: true,
    });

    series.slices.template.setAll({
      strokeWidth: 4,
      stroke: am5.color(0xffffff),
      cornerRadius: 10,
      shadowOpacity: 0.1,
      shadowOffsetX: 2,
      shadowOffsetY: 2,
    });

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(data);

    series.appear(1000, 100);
  }, [data]);
  return <div dir={'ltr'} id={id} style={{ width: '100%', height: '100%' }}></div>;
};
export default PieChart;
